import React from "react";
const Introduction = () => {
  return (
    <>
      <section className="h-auto bg-fixed pb-24 md:pt-16 relative">
        <div className="">
          <div className="flex lg:flex-row flex-col justify-between gap-8 z-10 relative my-5  md:pt-12">
            <div className="w-full lg:w-6/12 flex  flex-col justify-between px-2a">
              <div className="text-5xl lg:text-8xl font-light my-5  ">
                Get Guidance from Experts.
              </div>
              <div className="text-md ">
                Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
                vulputate libero et velit interdum, ac aliquet odio mattis.
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </div>
              <img src="../../../images/asthetic1.png" className="w-28" alt="" />
            </div>
            <div className="w-full lg:w-5/12">
              <img src="../../../images/experts.png" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Introduction;
