import React from "react";

const VideoStream = () => {
  return (
    <div>
      <div className="">VideoStream</div>
    </div>
  );
};

export default VideoStream;
