import { message } from "antd";
import axios from "axios";
import React from "react";
import { Link, Navigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const JobList = ({userData,ViewJob}) => {

    const imageurl = process.env.REACT_APP_API + "/pages/";

    const handleImageUrl = (imagePath) => {
        return `${imageurl}${imagePath}`;
    };

    return (
        <>
            <section className=" bg-fixed pb-5 ">
                <div className=" mx-auto block ">
                    <div className="mt-1 ">
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            navigation={true}
                            modules={[Navigation]}
                            className="mySwiper"
                            breakpoints={{
                                320: {
                                  slidesPerView: 1, 
                                },
                                768: {
                                  slidesPerView: 2, 
                                },
                                1024: {
                                  slidesPerView: 4, 
                                },
                              }}
                        >
                            {userData.map((item) => (
                                <SwiperSlide className="border border-solid border-gray-200 p-4 rounded-2xl shadow-lg bg-white my-5">
                                    <img
                                        src={handleImageUrl(item.Image)}
                                        className="mb-3 w-full md:w-28 mx-auto object-contain"
                                        alt=""
                                    />
                                    <div className="px-2 text-center mt-10">
                                        <div className="font-semibold">
                                            {item.Title}
                                        </div>
                                        <div className="text-sm">
                                            {item.Company}
                                        </div>
                                        {/* <Link href='/blog_writer_form'> */}
                                        <button   onClick={() => ViewJob(item.JobID)} className="bg-colorThemeBlue text-white mt-10 w-fit mx-auto px-5 py-2 rounded-lg">
                                            Apply
                                        </button>
                                        {/* </Link> */}
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
};

export default JobList;
