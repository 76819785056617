import React, { useState } from 'react';
import img1 from "../../src/components/img/forget.png";
import { Link, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import countryList from 'react-select-country-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { message } from 'antd';

function Register() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    country: '',
    countryCode: '',
    password: '',
    confirmPassword: ''
  });

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const { phone, country_code, otp,email,emailOtp} = location.state || {};

  const countries = countryList().getData();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone' && value.length > 10) return;
    setFormData({ ...formData, [name]: value });
  };

  const handleCountryChange = (e) => {
    const selectedCountry = countries.find(country => country.value === e.target.value);
    setFormData({
      ...formData,
      country: selectedCountry.label,
      countryCode: selectedCountry.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const payload = {
      country_code: country_code,
      phone: phone,
      otp: otp,
      email: email, 
      email_otp: emailOtp,
      password: formData.password,
      name: formData.name,
      con_password: formData.confirmPassword
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/user/register`, payload);
      message.success(response.data.Success)
      Navigate("/");
    } catch (error) {
      message.error(error.response.data.Error)
      setError('Registration failed. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className='mt-40 '></div>
      <div className='container mx-auto'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mb-10'>
          <div><img src={img1} alt='password' /></div>
          <div>
            <div className='w-full lg:w-3/4 bg-white border-8 border-t-colorPrimaryBlue shadow-lg px-10 py-5'>
              <h1 className='font-bold text-center text-lg'>Use your mobile number & password for login next time</h1>
              <form onSubmit={handleSubmit}>
                <div className='mt-5'>
                  <label className='mt-10'>Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                  />
                </div>
                {/* <div className='mt-5'>
                  <label className='mt-10'>Phone</label>
                  <input
                    type="number"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                  />
                </div>
                <div className='mt-5'>
                  <label className='mt-10'>Country</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleCountryChange}
                    className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                  >
                    {countries.map(country => (
                      <option key={country.value} value={country.value}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                <div className='mt-5 relative'>
                  <label className='mt-10'>New Password</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="New password"
                    value={formData.password}
                    onChange={handleChange}
                    className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="absolute top-[60%] transform -translate-y-1/2 right-3 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <div className='mt-5 relative'>
                  <label>Confirm Password</label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                  />
                  <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    className="absolute top-[60%] transform -translate-y-1/2 right-3 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                </div>
                {error && <div className='text-red-500 mt-3'>{error}</div>}
                <div className="flex justify-start text-center mt-5">
                  <button type="submit" className="w-full bg-colorThemeBlue text-white px-6 py-2 rounded-lg">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
