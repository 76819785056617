import React from "react";

function Refund() {
  return (
    <div className="text-justify">
      <div class="container mx-auto ">
        <h1 class="text-3xl my-4 font-bold">
          Cancellation &amp; Refund Policy
        </h1>
        <p class="text-black font-semibold my-4">
          Last updated on Oct 10th 2023
        </p>
        <p>No cancellations &amp; Refunds are entertained.</p>
      </div>
    </div>
  );
}

export default Refund;
