import React from "react";



const CreativeCanvaWorkshopSingle = () => {
  return (
    <div className="container pb-10 mx-auto">
      <div className="flex">
        <div className=" p-4">
          <img
            src="../../../images/canvaVideo.png"
            className="w-fit mx-auto"
            alt=""
          />
          <div className="border border-solid border-gray-300 bg-white rounded-lg p-4 mt-5">
            <div className="font-bold mb-3 text-lg">Transcription</div>
            Corem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar.
            <br />
            <br />
            Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna.
            Curabitur vel bibendum lorem. Morbi convallis convallis diam sit
            amet lacinia. Aliquam in elementum tellus.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreativeCanvaWorkshopSingle;
