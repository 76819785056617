import { Tabs } from "antd";
import React from "react";
import Notifications from "../components/Notifications";
import PurchaseHistory from "../components/PurchaseHistory";
import Settings from "../components/Settings";
import VideoStream from "../components/VideoStream";

const Cinema = () => {
  return (
    <div>
      <div className="md:w-10/12 h-[78vh] mx-auto w-full py-10">
        <Tabs
        tabPosition={'left'}
          defaultActiveKey="1"
          items={[
            {
              label: "Purchase History",
              key: "1",
              children: <PurchaseHistory />,
            },
            {
              label: "Video Stream",
              key: "2",
              children: <VideoStream />,
            },
            {
              label: "Notifications",
              key: "3",
              children: <Notifications />,
            },
            {
              label: "Settings",
              key: "4",
              children: <Settings />,
            },
            {
              label: "Log out",
              key: "5",
              children: "User will logout here...",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Cinema;
