import { Breadcrumb, Form, Input, Select, Space, Tabs, message } from "antd";
import React from "react";
const { Option } = Select;
const onChange = (key) => {
  console.log(key);
};

const VerificationInfo = () => {
  const [form] = Form.useForm();
  const onFinish = () => {
    message.success("Submit success!");
  };
  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const items = [
    {
      key: "1",
      label: "Email Verification",
      children: (
        <div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="Email"
              label="Email ID"
              rules={[
                {
                  type: "Email",
                  warningOnly: true,
                },
              ]}
            >
              <Input placeholder="Enter Email ID" className="w-[250px]" />
            </Form.Item>
            <Form.Item>
              <Space>
                <button
                  className="text-base cursor-pointer w-fit  py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white "
                  htmlType="submit"
                >
                  Submit
                </button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "2",
      label: "Personal Info",
      children: (
        <div>
          <Form layout="vertical" autoComplete="off">
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1 ">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                name="contactNumber"
                label="Contact number (Kindly add your WhatsApp number)"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Contact number (Kindly add your WhatsApp number)" />
              </Form.Item>
              <Form.Item
                name="schoolCollege"
                label="School/College Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="School/College Name" />
              </Form.Item>
              <Form.Item
                name="Email"
                label="Which Grade do you study in?"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="FYJC, SYJC, SY BMA, FY BSc,.." />
              </Form.Item>
              <Form.Item
                name="referral"
                label="Referral"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="SOM425" />
              </Form.Item>
            </div>
            <Form.Item>
              <Space>
                <button
                  className="text-base cursor-pointer w-fit  py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white "
                  htmlType="submit"
                >
                  Submit
                </button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: "3",
      label: "Payment Details",
      children: (
        <div>
          <Form layout="vertical" autoComplete="off">
            <Form.Item
              name="days"
              label="How many days would you want to attend?"
              rules={[
                {
                  required: true,
                },
                {
                  warningOnly: true,
                },
              ]}
            >
              <Select placeholder="Select Days">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
              </Select>
            </Form.Item>
          </Form>

          <div className="space-y-4">
            <div className="bg-white rounded-md border border-gray-200 border-solid flex items-center justify-between w-full p-5">
              <div className="text-xl font-bold">Credit Card</div>
              <div>
                <img
                  src="../../../images/credit-card 1.jpg"
                  alt=""
                  className="w-10"
                />
              </div>
            </div>
            <div className="bg-white rounded-md border border-gray-200 border-solid flex items-center justify-between w-full p-5">
              <div className="text-xl font-bold">Debit Card</div>
              <div>
                <img
                  src="../../../images/credit-card 1.jpg"
                  alt=""
                  className="w-10"
                />
              </div>
            </div>
            <div className="bg-white rounded-md border border-gray-200 border-solid flex items-center justify-between w-full p-5">
              <div>
                <img src="../../../images/gpay.jpg" alt="" />
              </div>
              <div>
                <img src="../../../images/upi.jpg" alt="" />
              </div>
            </div>
            <div className="bg-white rounded-md border border-gray-200 border-solid flex items-center justify-between w-full p-5">
              <div>
                <img src="../../../images/phonePay.jpg" alt="" />
              </div>
              <div>
                <img src="../../../images/rupay.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div className="container pb-10">
      <div className="mb-5 pt-5">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Homepage",
            },

            {
              title: "Celerbrate Cinema",
            },
            {
              title: "Verification",
            },
          ]}
        />
      </div>
      <div className="flex">
        <div className="md:w-4/12 md:block hidden border-r border border-l-0 border-t-0 border-b-0">
          <div className="h-full flex items-center justify-center">
            <div className="mt-10">
              <img
                src="../../../images/cinema_mobile.jpg"
                className=" object-contain mx-auto "
                alt=""
              />
              <div>
                <div>
                  <div className="mt-10 text-2xl font-bold">
                    Celebrate Cinema 2023
                  </div>
                  <div>Whistling Woods International</div>
                  <div
                    href="#"
                    className="text-colorThemeBlue text-sm font-semibold italic"
                  >
                    Terms & Condition*
                  </div>
                </div>
                <div>
                  <div className="font-bold mt-3 mb-2">Order Summary</div>
                  <div className="border border-b-2 border-r-0 border-t-0 border-l-0 ">
                    <div className="flex items-center justify-between">
                      <div>3rd November</div>
                      <div>₹150/-</div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>4th November</div>
                      <div>₹150/-</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div>To Pay</div>
                    <div className="font-bold text-lg">₹300/-</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-8/12 p-4">
          <div className="my-2 text-4xl">Event Registration</div>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default VerificationInfo;
