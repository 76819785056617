import React from 'react'
import img1 from "../../src/components/img/forget.png"

function ForgetPassword() {
  return (
    <>
    <div className='mt-40 '></div>
    <div className='container mx-auto  '>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mb-10'>
        <div><img src={img1} alt='passowrd'/></div>
        <div>
            <div className='w-full lg:w-3/4 bg-white shadow-lg shadow-[#000] px-3 py-5'>
                <h1 className='font-bold text-center text-lg'>Use your mobile number & password for login next time</h1>
                <form 
                // onSubmit={handleSubmit}
                >
                <div className='mt-5'>
                <label className='mt-10'>New Password</label>
                <input
                  type="text"
                  placeholder="New password"
                //   value={username}
                //   onChange={handleUsernameChange}
                  className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                />
                </div>
                <div className='mt-5'>
                <label >Confirm Password</label>
                <input
                  type="password"
                  placeholder="Confirm password"
                //   value={password}
                //   onChange={handlePasswordChange}
                  className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"

                />
                </div>
                <div className="flex justify-start mt-5">
                <button type="submit" className="w-full bg-colorPrimaryBlue text-white px-6 py-2 rounded-lg">Save</button>
                </div>
                </form>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ForgetPassword
