import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function MyAccountDropdown({ toggleAccountDropdown, isAccountDropdownOpen, handleLogout }) {

  // const [showDropdown,setShowDropdwon] = useState(false);

  
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleAccountDropdown}
          className="text-base flex gap-1 items-center py-4 px-8 text-black font-bold rounded-lg hover:text-second"
        >
          My Account
          {isAccountDropdownOpen ? (
            <BsChevronUp size={20} />
          ) : (
            <BsChevronDown size={20} />
          )}
        </button>
      </div>
      {isAccountDropdownOpen && ( 
        <div className="origin-top-right absolute  z-50 right-0 mt-2 w-48  ">
          <ul className="py-1 z-50 fixed mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" role="menuitem">
              <Link to="/dashboard/purchase">Purchase History</Link>
            </li>
            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" role="menuitem">
              <Link to="/dashboard/video"> Video Stream</Link>
            </li>
            <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" role="menuitem">
              <Link to="/dashboard/notifications">Notifications</Link>
            </li>
            {/* <li className="px-4 py-2 cursor-pointer hover:bg-gray-100" role="menuitem">
              <Link to="/">Settings</Link>
            </li> */}
            <li
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
              onClick={handleLogout}
              role="menuitem"
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default MyAccountDropdown;
