import React from "react";
import BasicTradingStratergy from "../components/BasicTradingStratergy";
import FindYourInterest from "../components/FindYourInterest";
import Introduction from "../components/Introduction";
import Students from "../components/Students";
import UpcomingEvents from "../components/UpcomingEvents";
import Container from "../components/Container";
import { FaArrowRight } from "react-icons/fa";
import Image from "./Image";

const Home = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
        <Container >
          <div className=" container mx-auto my-1  md:my-4 ">
            <div className="grid grid-cols-6 gap-2 md:mx-10 mt-1 md:mt-10">
              <div className="col-span-6 md:col-span-2 mx-2 md:mx-10 ">
                <h1 className="md:text-7xl text-5xl mb-1 mt-2 md:mt-12">Explore.</h1>
                <h1 className="md:text-7xl text-5xl my-1">Upskill.</h1>
                <h1 className="md:text-7xl text-5xl my-1">Elevate.</h1>
                <button className="px-16 my-10 py-3 bg-colorPrimaryBlue text-white font-semibold">
                  <div className="flex items-center ">
                    Discover Now
                    <FaArrowRight className="ml-1" />
                  </div>
                </button>
              </div>
              <div className="col-span-6 md:col-span-4">
                {/* <div className="grid grid-cols-3 gap-3"> */}
                  {/* <img src="" alt="explore" className="mt-12" />
                  <img src="" alt="explore" className="mt-12" />
                  <img src="" alt="explore" className="mt-12" /> */}
                  <Image/>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className=" container mx-auto  mt-10">
            <img
              src={"../../../images/banner.png"}
              className="w-full "
              alt=""
            />
            <h1 className=" container  mx-auto my-5 md:my-10 px-4 text-xl md:text-4xl text-black font-semibold ">
              Upcoming Events
            </h1>
            <UpcomingEvents />
            <div className=" container mx-auto my-5 md:my-10 px-4 text-xl md:text-4xl text-black font-semibold ">
              Find Your Interest
            </div>
            <FindYourInterest />
          </div>
        </Container>
      </div>
      <div className="bg-white">
        <Container>
          <div className=" mx-auto pt-10">
            <Introduction />
          </div>
        </Container>
      </div>
      <div className="bg-white">
        <Container>
          <div className=" container mx-auto pt-10">
            <Students />
          </div>
        </Container>
      </div>
      <div className="bg-gradient-to-t from-gray-50 via-gray-50 to-blue-100">
        <div className=" container mx-auto py-10">
          <BasicTradingStratergy />
        </div>
      </div>
    </>
  );
};

export default Home;
