import { Select, Input } from "antd";
import React, { useState } from "react";

function Notification() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const options = ["Option1", "Option2", "Option3"];

  const cardData = [
    {
      title: "AIDC",
      buttons: ["Reflection", "Standard Sale"],
      ended: 100,
      details: [
        { label: "Fully Diluted Valuation", value: "$102,000" },
        { label: "Vesting Schedule", value: "100% at TGE" },
        { label: "Liquidity", value: "70%" },
        { label: "Liquidity locked for", value: "180 days" },
      ],
    },
    {
      title: "AIDC",
      buttons: ["Reflection", "Standard Sale"],
      ended: 100,
      details: [
        { label: "Fully Diluted Valuation", value: "$102,000" },
        { label: "Vesting Schedule", value: "100% at TGE" },
        { label: "Liquidity", value: "70%" },
        { label: "Liquidity locked for", value: "180 days" },
      ],
    },
  ];

  return (
    <div>
      <div className="flex gap-3 mb-10 ">
        <Input.Search placeholder="input search text" style={{ width: 400 }} />
        <Select
          placeholder="Category"
          style={{ width: 200 }}
          options={options.map((option) => ({
            value: option,
            label: option,
          }))}
          onChange={(value) => setSelectedCategory(value)}
        />
      </div>

      <div className="bg-white h-full shadow-md justify-between rounded-md p-2 md:flex gap-3">
        <div className="md:flex gap-3">
          <div className="md:w-40 w-full">
            <div className="w-full h-full">
              <img
                src="../../../images/slider4.png"
                alt="log.png"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="block md:flex  flex-col justify-between">
            <div>
              <h1 className="font-semibold text-[16px] lg:text-xl my-1">Name of the Event/Video/course</h1>
              <p className="mt-2 mb-4 ">Author/Artist name</p>

              <div>Updated on: 24th August, 2023</div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3 bottom-0 mt-14">
                {/* <div classNamse="flex flex-rows"> */}
                <div >
                  <button className="bg-colorThemeBlue text-white py-1 px-5">Continue Watching</button>
                  <button className="mx-2">Restart</button>
                </div>
                <div className="text-snd flex md:block justify-center">
                  <button>View Details</button>
                {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
