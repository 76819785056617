import React from "react";
import "swiper/css";
import "swiper/css/navigation";

  const BlogGrid3 = ({ myProp, onClick }) => {

  if (!myProp) {
  console.error('Invalid myProp data:', myProp);
  return null;
}

const {  Image, Created_On, Title, Slug } = myProp;


  // const imageStyle = {
  //   backgroundImage: `url(${Image})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   width: '100%',
  //   height: '200px', // Set a specific height for the container
  // };
  
  return (
    <>
      <section className=" bg-fixed pb-5 ">
        <div className=" mx-auto block ">
      <button onClick={() => onClick(myProp.ID)}>
          <div className="mt-1 ">
              <div>
                <img
                  src={Image}
                  className="mb-3 w-full object-contain"
                  alt=""
                />
                 {/* <div style={imageStyle}></div> */}
                <div className="px-2">
                  <div className="text-blue-500 font-semibold">
                    {Title}
                  </div>
                  <div className="bg-white rounded-full shadow-sm absolute -top-3 -right-2  text-center p-3 ">
                    <div className="font-bold text-2xl text-gray-700 leading-5 pt-2">
                      {Created_On}
                    </div>
                    <div className="text-xs">{Slug}</div>
                  </div>
                </div>
              </div>
          </div>
        </button>
        </div>
      </section>
    </>
  );
};

export default BlogGrid3;
