import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const countryCodes = [
  { code: '+1', country: 'USA' },
  { code: '+91', country: 'India' },
  { code: '+65', country: 'Singapore' },
  // Add more country codes as needed
];

const LoginForm = () => {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

 

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    setPhone('');
    setPassword('');
  };

  const handleUsernameChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    axios
      .post(`${process.env.REACT_APP_API}/user/login`, {
        country_code: countryCode,
        phone: phone,
        password: password
      })
      .then((response) => {
        message.success(response.data.Success);
        localStorage.setItem('jwt', response?.data?.JWT)
        navigate("/");
        setPhone('');
        setPassword('');
        closeLoginModal();
      })
      .catch((error) => {
        message.error(error.response.data.Error);
      });
  };

  const handleForgetPassword = () => {
    closeLoginModal();
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setPhone(value);
    if (/^\d{10}$/.test(numericValue)) {
      const completePhoneNumber = numericValue;
      handleSubmit(completePhoneNumber);
    } else {
      setError('');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      setLoginModalOpen(false)
    }
  };

  return (
    <>
      <div className="modal-overlay" onClick={handleOverlayClick} style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 999 }}>
        <div className="modal-container border-8 border-t-colorPrimaryGold " style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", padding: "20px", borderRadius: "8px", maxWidth: "400px", width: "80%" }}>
        <span style={{ float: "right", cursor: "pointer" }}  onClick={() => navigate("/")}>X</span>
          <div className="flex justify-center">
            <h2 className="font-bold text-xl">Welcome back </h2>
          </div>
          <Form>
            <div className=" flex items-center border-b border-gray-300 border-b-colorThemeBlue px-3 py-2 mt-10 rounded-lg mb-3 border-2 w-full">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="border-none bg-transparent outline-none md:mr-2 w-20 md:w-full"
              >
                <option value="">Select</option>
                {countryCodes.map((code) => (
                  <option key={code.code} value={code.code}>
                    {code.code} ({code.country})
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Mobile number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  handlePhoneChange(e); 
                }}
                className="border-none bg-transparent outline-none flex-grow"
              />
            </div>

            <div className="flex items-center rounded-lg   w-full">
              <input
                type={showPassword ? "text" : "password"} 
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className="border-b-colorThemeBlue px-3 py-2 rounded-lg mb-3 border-2 w-full"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye} 
                onClick={togglePasswordVisibility}
                className="text-gray-400 cursor-pointer"
              />
            </div>

            <div className="flex justify-start mt-5">
              <Button onClick={handleSubmit} type="primary" htmlType="submit" className="bg-colorPrimaryBlue mb-5 text-white px-6 py-2">
                Continue
              </Button>
            </div>
          </Form>

          <Link to="/forgetpassword" onClick={handleForgetPassword} className="text-colorPrimaryBlue underline">
            Forgot password?
          </Link>
          <div className="mt-5 mb-10">
            Don’t have an account? <Link to="/register" className="text-colorPrimaryBlue underline">Sign-up</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
