import { Select } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import UpcomingEventsPageNoSwiper from "../components/UpcomingEventsPageNoSwiper";
import Container from "../components/Container";

const Masterclass = () => {
  return (
    <>
      <div className="container mx-auto bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
        <div className="  mx-auto pt-10">
          <img src={"../../../images/banner.png"} className="w-full " alt="" />
          <div className="container mx-auto text-2xl md:text-5xl my-5 text-black font-semibold pt-16">
            Masterclass
          </div>
          <Container>
          <div className="container mx-auto block md:flex gap-3 mb-10">
              <Search
                placeholder="input search text"
                className="md:w-[400px] w-full"
              />
              <div className="flex mt-5 md:mt-0 gap-2">
                <Select
                  placeholder="Category"
                  style={{ width: 200 }}
                  options={[
                    {
                      value: "Category 1",
                      label: "Category 1",
                    },
                    {
                      value: "Category 2",
                      label: "Category 2",
                    },
                    {
                      value: "Category 3",
                      label: "Category 3",
                    },
                    {
                      value: "Category 4",
                      label: "Category 4",
                    },
                  ]}
                />
                <Select
                  style={{ width: 200 }}
                  placeholder="Live"
                  options={[
                    {
                      value: "Live",
                      label: "Live",
                    },
                  ]}
                />
              </div>
            </div>
          </Container>
          <UpcomingEventsPageNoSwiper />
        </div>
      </div>
    </>
  );
};

export default Masterclass;
