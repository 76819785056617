import React, { useState } from "react";
import homeBackground from "../components/img/home2.png";
import homeBackground1 from "../components/img/home1.png";
import homeBackground2 from "../components/img/home.png";

const Image = () => {
  // State to manage active card
  const [activeCard, setActiveCard] = useState("card-1");

  // Function to handle card click
  const handleCardClick = (cardId) => {
    setActiveCard(cardId);
  };

  return (
    <>
      <div className="">
        <div class="projects mt-7">
          <a class="projects__card" href="#card-1" onClick={() => handleCardClick("card-1")}>
            <div class={`projects__card-container ${activeCard === "card-1" ? "active" : ""}`} id="card-1">
              <img src={homeBackground} alt="" />
              <div className="content absolute bottom-5 text-white">
                <div className="text-4xl font-normal bg-[#F18F01] rounded-full w-20 h-20 flex items-center">
                  <span className="text-center btn">Masterclass</span>
                </div>
                <p className="paragraph">Real-world learnings from the best.</p>
              </div>
            </div>
          </a>

          <a class="projects__card" href="#card-3" onClick={() => handleCardClick("card-3")}>
            <div class={`projects__card-container ${activeCard === "card-3" ? "active" : ""}`} id="card-3">
              <img src={homeBackground} alt="" />
              <div className="content absolute bottom-5 text-white">
                <div className="text-4xl font-normal bg-[#115D98] rounded-full w-20 h-20 flex items-center">
                  <span className="text-center btn">Webinar</span>
                </div>
                <p className="paragraph">Real-world learnings from the best.</p>
              </div>
            </div>
          </a>

          <a class="projects__card" href="#card-2" onClick={() => handleCardClick("card-2")}>
            <div class={`projects__card-container ${activeCard === "card-2" ? "active" : ""}`} id="card-2">
              <img src={homeBackground} alt="" />
              <div className="content absolute bottom-5 text-white">
                <div className="text-4xl font-normal bg-[#147B85] rounded-full w-20 h-20 flex items-center">
                  <span className="text-center btn">Podcast</span>
                </div>
                <p className="paragraph">Real-world learnings from the best.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Image;
