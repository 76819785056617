import React from "react";
import "swiper/css";
import "swiper/css/navigation";
const data = [
    {
        key: 1,
        title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog1.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 2,
        title: "Web Designing, Digital Marketing, & Android Devel...",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog2.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 3,
        title: "Chai & Why?",
        desc: "Prithvi Chauhan | Recorded",
        image: "../../../images/blog3.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 1,
        title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog1.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 2,
        title: "Web Designing, Digital Marketing, & Android Devel...",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog2.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 3,
        title: "Chai & Why?",
        desc: "Prithvi Chauhan | Recorded",
        image: "../../../images/blog3.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 1,
        title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog1.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 2,
        title: "Web Designing, Digital Marketing, & Android Devel...",
        desc: "Aasha Pallav | In-person",
        image: "../../../images/blog2.png",
        date: "29",
        month: "Read More",
    },
    {
        key: 3,
        title: "Chai & Why?",
        desc: "Prithvi Chauhan | Recorded",
        image: "../../../images/blog3.png",
        date: "29",
        month: "Read More",
    },
];
console.log(data);
const ListLayout = () => {
    return (
        <>
            <section className=" bg-fixed pb-5 ">
                <div className=" mx-auto block ">
                    <a href="/blog_inner">
                        <div className="mt-1 grid grid-cols-1 gap-4">
                            {data.map((item) => (
                                <div className="block md:flex gap-4 border border-solid border-gray-50 shadow-lg rounded-xl ">
                                    <img
                                        src={item.image}
                                        className=" w-60 object-contain"
                                        alt=""
                                    />
                                    <div className="p-2 flex-col flex justify-between">
                                        <div>
                                            <div className="text-lg text-gray-700 ">
                                                {item.desc}
                                            </div>
                                            <div className="text-black text-2xl font-semibold">
                                                {item.title}
                                            </div>
                                        </div>
                                        <div className="text-md font-semibold text-colorThemeBlue underline">
                                            {item.month}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </a>
                </div>
            </section>
        </>
    );
};

export default ListLayout;
