import React from "react";
import BasicTradingStratergyGid4 from "../components/BasicTradingStratergyGid4";
import BasicTradingStratergyGrid2 from "../components/BasicTradingStratergyGrid2";
import UpcomingEvents from "../components/UpcomingEvents";
import Container from "../components/Container";

const WhatsNew = () => {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
        <Container>
        <div className="  mx-auto pt-10">
          <img src={"../../../images/banner.png"} className="w-full " alt="" />
          <div className="container mx-auto my-7 text-2xl lg:text-5xl  text-black font-semibold pt-16">
            Upcoming Events
          </div>
          <UpcomingEvents />
        </div>
        </Container>
      </div>
      <div className="bg-gradient-to-t from-gray-50 via-gray-50 to-blue-100">
      <Container>
        <div className=" container mx-auto py-10">
          <div className="text-2xl md:text-5xl mb-5 text-black font-semibold pt-10">CB News</div>
          <BasicTradingStratergyGid4 />
        </div>
        </Container>
        <Container>
        <div className=" container mx-auto py-10">
          <div className="text-xl lg:text-5xl mb-5 text-black font-semibold ml-2 pt-10">Read From Our Latest Blogs</div>
          <BasicTradingStratergyGrid2 />
        </div>
      </Container>

      </div>
    </>
  );
};

export default WhatsNew;
