import { Pagination, Select } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import BlogGrid4 from "../components/BlogGrid4";
import ListLayout from "../components/ListLayout";
import Container from "../components/Container";

const NewsMain = () => {
  return (
    <>
    <Container>
      <div className="bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
        <div className=" container mx-auto pt-10">
          <div className="relative">
            <img
              src={"../../../images/newsBanner.png"}
              className="w-full h-96 object-cover rounded-xl overflow-hidden"
              alt=""
            />
            <div className="absolute z-10 top-0 right-0 left-0 bottom-0 bg-black/50 rounded-xl overflow-hidden"></div>
            <div className="absolute bottom-10 left-10 text-white z-20">
              <div className="my-4 text-2xl">
                CBSE Compartment Result 2023 Live: CBSE <br />
                Class 10, 12 results at cbse.nic.in soon.
              </div>
              <div className="text-white underline cursor-pointer">
                <a href="/news_inner">Read more</a>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="text-lg md:text-4xl mb-5 text-black font-semibold">
              Latest from the Educational Industry
            </div>
            <BlogGrid4 />
          </div>
          <div className="bg-white p-4 rounded-md mt-16 shadow-lg">
            <div className="text-4xl mb-5 text-black font-semibold ">
              Blog Posts
            </div>
            <div className="container mx-auto block md:flex gap-3 mb-10">
              <Search
                placeholder="input search text"
                className="md:w-[400px] w-full"
              />
              <div className="flex mt-5 md:mt-0 gap-2">
                <Select
                  placeholder="Category"
                  style={{ width: 200 }}
                  options={[
                    {
                      value: "Category 1",
                      label: "Category 1",
                    },
                    {
                      value: "Category 2",
                      label: "Category 2",
                    },
                    {
                      value: "Category 3",
                      label: "Category 3",
                    },
                    {
                      value: "Category 4",
                      label: "Category 4",
                    },
                  ]}
                />
                <Select
                  style={{ width: 200 }}
                  placeholder="Live"
                  options={[
                    {
                      value: "Live",
                      label: "Live",
                    },
                  ]}
                />
              </div>
            </div>
            <ListLayout />
            <div className="text-center pb-10">
              <Pagination defaultCurrent={1} total={50} />
            </div>
          </div>
        </div>
      </div>
      </Container>
    </>
  );
};

export default NewsMain;
