import React from "react";
import "swiper/css";
import "swiper/css/navigation";
const data = [
  {
    key: 1,
    title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider1.png",
    date: "29",
    month: "October",
  },
  {
    key: 2,
    title: "Web Designing, Digital Marketing, & Android Devel...",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider2.png",
    date: "29",
    month: "October",
  },
  {
    key: 3,
    title: "Chai & Why?",
    desc: "Prithvi Chauhan | Recorded",
    image: "../../../images/slider3.png",
    date: "29",
    month: "October",
  },
  {
    key: 4,
    title: "Wealth Creation Webinar",
    desc: "Dr. Aniruddh Sahai | Live",
    image: "../../../images/slider4.png",
    date: "29",
    month: "October",
  },
  {
    key: 1,
    title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider1.png",
    date: "29",
    month: "October",
  },
  {
    key: 2,
    title: "Web Designing, Digital Marketing, & Android Devel...",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider2.png",
    date: "29",
    month: "October",
  },
  {
    key: 3,
    title: "Chai & Why?",
    desc: "Prithvi Chauhan | Recorded",
    image: "../../../images/slider3.png",
    date: "29",
    month: "October",
  },
  {
    key: 4,
    title: "Wealth Creation Webinar",
    desc: "Dr. Aniruddh Sahai | Live",
    image: "../../../images/slider4.png",
    date: "29",
    month: "October",
  },
  {
    key: 3,
    title: "Chai & Why?",
    desc: "Prithvi Chauhan | Recorded",
    image: "../../../images/slider3.png",
    date: "29",
    month: "October",
  },
  {
    key: 4,
    title: "Wealth Creation Webinar",
    desc: "Dr. Aniruddh Sahai | Live",
    image: "../../../images/slider4.png",
    date: "29",
    month: "October",
  },
];
console.log(data);
const UpcomingEventsPageNoSwiper = () => {
  return (
    <>
      <section className=" bg-fixed pb-5 my-5">
        <div className="container mx-auto block ">
        <a href='/creative_workshop'>
          <div className="mt-1 grid-cols-1 grid lg:grid-cols-4 md:gap-4">
            {data.map((item) => (
              <div>
                <img
                  src={item.image}
                  className="mb-3 w-full object-contain"
                  alt=""
                />
                <div className="px-2">
                  <div className="text-blue-500 font-semibold">
                    {item.title}
                  </div>
                  <div className="text-sm">{item.desc}</div>
                  <div className="bg-white rounded-full shadow-sm absolute -top-3 -right-2  text-center p-3 ">
                    <div className="font-bold text-2xl text-gray-700 leading-5 pt-2">
                      {item.date}
                    </div>
                    <div className="text-xs">{item.month}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default UpcomingEventsPageNoSwiper;
