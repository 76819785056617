import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const data = [
  {
    key: 1,
    title:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. repto nikolo deslip tekoma.",
    desc: "Aasha Pallav ",
    image: "https://placehold.co/40x40",
    designation: "Designation",
  },
  {
    key: 2,
    title:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. repto nikolo deslip tekoma....",
    desc: "Aasha Pallav ",
    image: "https://placehold.co/40x40",
    designation: "Designation",
  },
  {
    key: 3,
    title:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. repto nikolo deslip tekoma.?",
    desc: "Prithvi Chauhan ",
    image: "https://placehold.co/40x40",
    designation: "Designation",
  },
  {
    key: 4,
    title:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. repto nikolo deslip tekoma.",
    desc: "Dr. Aniruddh Sahai ",
    image: "https://placehold.co/40x40",
    designation: "Designation",
  },
  {
    key: 5,
    title:
      "Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. repto nikolo deslip tekoma.",
    desc: "Lorem ipsum Dolor ",
    image: "https://placehold.co/40x40",
    designation: "Designation",
  },
];
console.log(data);
const Students = () => {
  return (
    <>
      <section className=" bg-fixed pb-5 ">
        <div className="text-3xl text-black font-normal my-6">
          What our students say{" "}
        </div>
        <div className=" mx-auto block ">
          <div className="mt-1 ">
            <Swiper
              slidesPerView={4}
              spaceBetween={40}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1, // 1 slide for screens smaller than 320px
                },
                768: {
                  slidesPerView: 2, // 2 slides for screens larger than 768px
                },
                1024: {
                  slidesPerView: 4, // 4 slides for screens larger than 1024px
                },
              }}
            >
              {data.map((item) => (
                <SwiperSlide >
                  <div className="bg-white shadow-lg rounded-md mb-10">
                    <div className="p-4">
                      <div className="text-black text-sm mb-5 ">
                        {item.title}
                      </div>
                      <div className="flex gap-2 items-center">
                        <div>
                          <img
                            src={item.image}
                            className="rounded-full"
                            alt=""
                          />
                        </div>
                        <div>
                          <div>{item.desc}</div>
                          <div className="text-sm">{item.designation}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Students;
