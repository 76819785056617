import React from "react";
import DetailedInfo from "../components/CinemaPage";

const Cinema = () => {
  return (
    <div>
      <div className="md:w-10/12 mx-auto w-full">
        <DetailedInfo />
      </div>
    </div>
  );
};

export default Cinema;
