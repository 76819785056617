import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Select,
  Space,
  Tabs,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
const onChange = (key) => {
  console.log(key);
};

const CBForm = () => {
  const items = [
    {
      key: "1",
      label: "Job Description",
      children: (
        <div>
          Borem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
          fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
          elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
          lectus.
          <div className="mt-5 mb-2 font-semibold">Job Role</div>
          <ul>
            <li>Forem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>
              Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
            </li>
            <li>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos..
            </li>
            <li>Gorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          </ul>
          <div className="mt-5 mb-2 font-semibold">Requirement</div>
          <ul>
            <li>Forem ipsum dolor sit amet, consectetur adipiscing elit.</li>
            <li>
              Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
            </li>
            <li>
              Class aptent taciti sociosqu ad litora torquent per conubia
              nostra, per inceptos himenaeos..
            </li>
            <li>Gorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          </ul>
          <Form.Item>
            <Space>
              <button
                className="text-base cursor-pointer w-fit  py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white "
                htmlType="submit"
              >
                Submit
              </button>
            </Space>
          </Form.Item>
        </div>
      ),
    },
    {
      key: "2",
      label: "User Details",
      children: (
        <div>
          <Form layout="vertical" autoComplete="off">
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1 ">
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
              <Form.Item
                name="contactNumber"
                label="Email ID"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Enter email id" />
              </Form.Item>
              <Form.Item
                name="schoolCollege"
                label="Which course are you currently pursuing?"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Mumbai, Pune" />
              </Form.Item>
              <Form.Item
                name="Email"
                label="College Name?"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="College Name" />
              </Form.Item>
              <Form.Item
                name="referral"
                label="University Name? "
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Some high School" />
              </Form.Item>
              <Form.Item
                name="referral"
                label="Any previous Experience? "
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Input placeholder="Some high School" />
              </Form.Item>
              <Form.Item
                name="referral"
                label="State your experience in short "
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item
                name="referral"
                label="Upload your resume"
                rules={[
                  {
                    required: true,
                  },
                  {
                    warningOnly: true,
                  },
                ]}
              >
                <Upload>
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
            </div>
            <Form.Item
              name="referral"
              label="Add Portfolio link"
              rules={[
                {
                  required: true,
                },
                {
                  warningOnly: true,
                },
              ]}
            >
              <Space>
                <Input />
                <button
                  className="text-base cursor-pointer w-fit  py-1 px-10  rounded-md text-black border border-dashed border-gray-300 "
                  htmlType="submit"
                >
                  Add items
                </button>
              </Space>
            </Form.Item>
            <Form.Item
              name="referral"
              label="More about you"
              rules={[
                {
                  required: true,
                },
                {
                  warningOnly: true,
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item>
              <Space>
                <button
                  className="text-base cursor-pointer w-fit  py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white "
                  htmlType="submit"
                >
                  Submit
                </button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];
  return (
    <div className="container mx-auto pb-10">
      <div className="mb-5 pt-5 px-10">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Homepage",
            },
            {
              title: "Internship",
            },
            {
              title: "CB Brand Ambassador Form",
            },
          ]}
        />
      </div>
      <div className="flex px-10">
        <div className="md:w-8/12 p-4 border-r border border-l-0 border-t-0 border-b-0">
          <div className="my-2 text-4xl">CB Brand Ambassador Form</div>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
        <div className="md:w-4/12 md:block hidden ">
          <div className="h-full flex items-start justify-center">
            <div className="mt-10">
              <img
                src="../../../images/survey.png"
                className=" object-contain mx-auto p-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CBForm;
