import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const data = [
  {
    key: 1,
    image: "../../../images/fyi1.png",
    href: "/masterclass",
  },
  {
    key: 2,
    image: "../../../images/fyi2.png",
    href: "/webinar",
  },
  {
    key: 3,
    image: "../../../images/fyi3.png",
    href: "/podcast",
  },
  {
    key: 4,
    image: "../../../images/fyi4.png",
    href: "https://placehold.co/400x400",
  }
];
console.log(data);
const 
FindYourInterest = () => {
  return (
    <>
      <section className=" bg-fixed ">
        <div className="container mx-auto block ">
          <div className="pb-10 ">
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1, // 1 slide for screens smaller than 320px
                },
                768: {
                  slidesPerView: 2, // 2 slides for screens larger than 768px
                },
                1024: {
                  slidesPerView: 4, // 4 slides for screens larger than 1024px
                },
              }}
            >
              {data.map((item) => (
                <SwiperSlide className=" relative overflow-hidden">
                  <a href={item.href}>
                    <img src={item.image} className="" alt="" />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default FindYourInterest;
