import React, { useEffect } from "react";
import { Tabs } from "antd";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { IoIosNotifications } from "react-icons/io";
import { MdVideoLibrary } from "react-icons/md";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Container from "../components/Container";
import TermsCondition from "../components/Terms&Condition";
import Policy from "../components/Policy";
import Refund from "../components/Refund";

const { TabPane } = Tabs;

const SecondTab = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "terms-conditions",
      tab: (
        <div className="flex items-center gap-1">
          <BiSolidPurchaseTag />
          Terms & Conditions
        </div>
      ),
      path: "/details/terms-conditions",
      component: <TermsCondition />,
    },
    {
      key: "privacy-policy",
      tab: (
        <div className="flex items-center gap-1">
          <IoIosNotifications />
          Privacy Policy
        </div>
      ),
      path: "/details/privacy-policy",
      component: <Policy />,
    },
    {
      key: "cancellation-refund",
      tab: (
        <div className="flex items-center gap-1">
          <MdVideoLibrary />
          Cancellation & Refund Policy
        </div>
      ),
      path: "/details/cancellation-refund",
      component: <Refund />,
    },
  ];

  useEffect(() => {
    // Optionally, you can set active tab state based on path
    // setActiveTab(location.pathname);
  }, [location.pathname]);

  const handleTabChange = (key) => {
    navigate(key);
  };

  return (
    <Container>
      <div className="md:mt-5 text-white">Homepage</div>
      <div className="md:my-8">
        <div className="container hidden lg:block md:my-10">
          <Tabs
            tabPosition="left"
            activeKey={location.pathname}
            onChange={handleTabChange}
          >
            {items.map((item) => (
              <TabPane tab={item.tab} key={item.path}>
                <Outlet />
              </TabPane>
            ))}
          </Tabs>
        </div>
        <div className="container block lg:hidden my-10 overflow-x-auto">
          <Tabs
            activeKey={location.pathname}
            onChange={handleTabChange}
          >
            {items.map((item) => (
              <TabPane tab={item.tab} key={item.path}>
                <Outlet />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </Container>
  );
};

export default SecondTab;
