import { Pagination, Select, message } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import BlogGrid2 from "../components/BlogGrid2";
import BlogGrid3 from "../components/BlogGrid3";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Container from "../components/Container";

const BlogMain = () => {
  const [options, setOptions] = useState([]);
  const [blog, setBlog] = useState([]);
  const [blogDetails, setBlogDetails] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigate = useNavigate();

  const handleSelect = () => {
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_USER + "categories",
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          setOptions(res.Success);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  const handleblogView = () => {
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_USER + "blog_list",
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          setBlog(res.Success);
          console.log(blog.Title, "bllll");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  const handleblogViewDetails = (ID, Slug, Title, Author, Created_On) => {
    const postData = {
      id: ID,
    };

    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_USER + "view_blog",
      data: postData,
    })
      .then(async function (response) {
        let res = await response.data.Success;
        navigate(`/${Slug}`, {
          state: {
            htmlCode: res,
            blog: Title,
            Author: Author,
            Created_On: Created_On,
          },
        });
        setBlogDetails(res);
        handleblogView();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.Error) {
          message.error(err.response.data.Error);
        }
      });
  };
 
  useEffect(() => {
    handleSelect();
    handleblogView();
  }, []);

  const handleBlogClick = (ID, Slug, Title, Author, Created_On) => {
    handleblogViewDetails(ID, Slug, Title, Author, Created_On);
  };

  const htmlContent =
    "<p>Your HTML content goes here</p><h2>Another heading</h2><p>More content</p>";
  return (
    <>
    <Container>
      <div className= " container bg-gradient-to-b  from-gray-50 via-gray-50 to-blue-100">
        <div className="  mx-auto pt-10">
          <div className="relative">
            <img
              src={"../../../images/blogBanner.png"}
              className="w-full "
              alt=""
            />
            <div className=" absolute -top-10 lg:top-16 md:left-10 left-3">
              <div className=" text-lg lg:text-5xl font-bold">The Blog</div>
              <div className="lg:my-12 text-[11px] mb-2 lg:text-2xl w-40 md:w-full">
                Show your potential in content writing. Write for us.
              </div>
              <div className="bg-colorThemeBlue cursor-pointer text-white rounded-lg px-6 text-sm md:text-base py-2 w-fit">
                Register
              </div>
            </div>
          </div>
          <BlogGrid2 />
          <div className="container mx-auto my-4 bg-white p-4 rounded-md py-6 mt-16 shadow-lg">
            <div className="text-4xl my-8 text-black font-semibold ">
              Blog Posts
            </div>
            <div className="flex gap-3 mb-10 ">
              <Search placeholder="input search text" style={{ width: 400 }} />
              <Select
                placeholder="Category"
                style={{ width: 200 }}
                options={options.map((option) => ({
                  value: option,
                  label: option,
                }))}
                onChange={(value) => setSelectedCategory(value)}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3  gap-4">
              {blog &&
                blog
                  .filter(
                    (item) =>
                      !selectedCategory ||
                      item.Categories.includes(selectedCategory)
                  )
                  .map((item, index) => (
                    <BlogGrid3
                      key={index}
                      myProp={{
                        key: index,
                        ID: item.ID,
                        Image: item.Image,
                        Created_On: item.Created_On,
                        Title: item.Title,
                        Slug: item.Slug,
                      }}
                      onClick={() =>
                        handleBlogClick(
                          item.ID,
                          item.Slug,
                          item.Title,
                          item.Author,
                          item.Created_On
                        )
                      }
                    />
                  ))}
            </div>

            <div className="text-center pb-10">
              <Pagination defaultCurrent={1} total={50} />
            </div>
          </div>
        </div>
      </div>
      </Container>
    </>
  );
};

export default BlogMain;
