// Dropdown.js
import React from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'; // Import arrow icons
import { Link, useLocation } from 'react-router-dom';

const Dropdown = ({ isOpen, toggleDropdown }) => {
const location = useLocation();


    const isActive = (path) => {
        return location.pathname === path ? "text-blue-500" : "";
      };

  return (
    <div className={`relative`}>
      <span className="cursor-pointer" onClick={toggleDropdown}>
        {isOpen ? <BsChevronUp /> : <BsChevronDown />}
        <div className={`absolute bg-white py-2 my-5  w-full ${isOpen ? 'block' : 'hidden'}`}>
          <ul className='list-disc'>
          <li
                className={`md:text-unset text-center my-1 font-normal text-base ${isActive(
                  "/masterclass"
                )}`}
              >
                <Link to="/masterclass">Masterclass</Link>
              </li>
          <li
                className={`md:text-unset text-center my-1 font-normal text-base ${isActive(
                  "/webinar"
                )}`}
              >
                <Link to="/webinar">Webinar</Link>
              </li>
         
              <li
                className={`md:text-unset text-center my-1 font-normal text-base ${isActive(
                  "/podcast"
                )}`}
              >
                <Link to="/podcast">Podcast</Link>
              </li>
              <li
                className={`md:text-unset text-center my-1 font-normal text-base ${isActive(
                  "/blog"
                )}`}
              >
                <Link to="/blog">Blogs</Link>
              </li>
            {/* Add more webinar links as needed */}
          </ul>
        </div>
      </span>
    </div>
  );
};

export default Dropdown;
