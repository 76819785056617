import { Breadcrumb } from "antd";
import React from "react";
import { PiPaperPlaneTiltLight } from "react-icons/pi";
const CinemaPage = () => {
  return (
    <div className="container ">
      <div className="mb-5 pt-5">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Homepage",
            },

            {
              title: "Celerbrate Cinema",
            },
          ]}
        />
      </div>
      <div className="mt-10">
        <img
          src="../../../images/cinema.jpg"
          className="lg:pb-10 w-full lg:h-auto h-52 object-cover mx-auto hidden md:block "
          alt=""
        />
        <img
          src="../../../images/cinema_mobile.jpg"
          className=" object-contain mx-auto block md:hidden "
          alt=""
        />
      </div>
      <div className="pb-10 leading-6 md:w-full w-11/12 mx-auto ">
        <div className="my-2 text-xl font-bold  md:text-4xl">Celebrate Cinema 2023</div>
        <div className="my-2">
          By Whistling Woods International | Last Updated on <b>16-10-2023</b>
        </div>
        <div className="my-2">
          Starting from <b>₹150/-</b>
        </div>
        <div className="flex gap-2 items-center mt-4">
          <a
            href="/verification_page"
            className="text-base cursor-pointer w-fit  py-2 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-xl text-white " rel="noreferrer"
          >
            Book Now
          </a>
          <div className="flex items-center gap-2 hover:bg-slate-100 cursor-pointer p-2 rounded-xl">
            <PiPaperPlaneTiltLight size={20} /> Share
          </div>
        </div>
        <div className="mt-4 bg-white rounded-lg p-5 shadow-lg ">
          <div className="mb-4 text-3xl font-medium">
            About Celebrate Cinema{" "}
          </div>
          <div className="my-4">
            Hello Cinephiles! Mark your calendars and book your seats for
            Celebrate Cinema 2023. Walk the red carpet of an experience that
            will ignite your passion for cinema. Whether you are a budding
            filmmaker, an aspiring actor, or just someone who loves the magic of
            the movies, there's something for everyone.  This year at Celebrate
            Cinema 2023, we'll explore what's in and what's out in the future of
            storytelling.
          </div>
          <div className="my-2">
            <b>Venue</b>: Whistling Woods International, Film City Complex,
            Aarey Colony, Goregaon, Mumbai, Maharashtra 400065
          </div>
          <div className="my-2">
            <b>Days</b>: 3rd & 4th November
          </div>
          <div className="my-2">
            <b>Time</b>: 12 pm onwards
          </div>
        </div>
        <div className="mt-4 bg-white rounded-lg p-5 shadow-lg ">
          <div className="mb-4 text-3xl font-medium">What to Expect? </div>
          <div className="my-2">
            <b>30+ Film Workshops:</b>: Dive into the art and craft of
            filmmaking, guided by industry experts.
          </div>
          <div className="my-2">
            <b>3 Panel Discussions</b>: Explore emerging film trends and
            interactive narratives from seasoned filmmakers and artists.
          </div>
          <div className="my-2">
            <b>Cinematic Experiences</b>: Walk through immersive exhibitions,
            installations, and experience zones as you immerse yourself in the
            world of art and cinema.
          </div>
          <div className="my-2">
            <b>The Future of Storytelling</b>: Explore groundbreaking
            narratives, emerging technologies, and innovative storytelling
            techniques that are shaping the future.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CinemaPage;
