import { Select, Input } from "antd";
import React, { useState } from "react";

function Books() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const options = ["Option1", "Option2", "Option3"]; 

  const cardData = [
    {
      title: "AIDC",
      buttons: ["Reflection", "Standard Sale"],
      ended: 100,
      details: [
        { label: "Fully Diluted Valuation", value: "$102,000" },
        { label: "Vesting Schedule", value: "100% at TGE" },
        { label: "Liquidity", value: "70%" },
        { label: "Liquidity locked for", value: "180 days" },
      ],
    },
    {
      title: "AIDC",
      buttons: ["Reflection", "Standard Sale"],
      ended: 100,
      details: [
        { label: "Fully Diluted Valuation", value: "$102,000" },
        { label: "Vesting Schedule", value: "100% at TGE" },
        { label: "Liquidity", value: "70%" },
        { label: "Liquidity locked for", value: "180 days" },
      ],
    },
  ];

  return (
    <div>
      <div className="flex gap-3 mb-10 ">
        <Input.Search placeholder="input search text" style={{ width: 400 }} />
        <Select
          placeholder="Category"
          style={{ width: 200 }}
          options={options.map((option) => ({
            value: option,
            label: option,
          }))}
          onChange={(value) => setSelectedCategory(value)}
        />
      </div>

      <div className="bg-white shadow-md h-auto md:h-[146px] justify-between rounded-md md:flex gap-3">
        <div className="md:flex gap-3">
          <div className="md:w-40 w-full">
            <div className="w-full h-full">
              <img
                src="../../../images/canvaVideo.png"
                alt="log.png"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            {/* <div className="flex justify-between flex-col"> */}
              <div className="mt-4 md:mt-0">
              <h1 className="text-lg lg:text-xl font-normal">Name of the Event/Video/course</h1>
              <p className="mt-1 text-colorText text-lg lg:text-xl">Author/Artist name</p>
              </div>

              <div className="text-xs lg:text-lg font-bold my-2 md:my-0">Virtual Event | Event on: <span className="text-colorThemeBlue font-light">24th August, 2023</span></div>
            </div>
          {/* </div> */}
        </div>
        <div className="grid grid-cols-1 items-end justify-end mt-6 md:mt-0">
          <div className=" top-0 gap-2 text-colorPrimaryBlue">
            <button className="mx-2">View Details</button>
            <button>Send Ticket</button>
          </div>
        </div>
        <div className="w-full md:hidden  mt-2 px-14 h-4 block  bg-colorPrimaryGold  text-xs object-cover  text-center  items-end justify-end">
            <p className="   ">Video</p>
          </div>
        <div className="grid grid-cols-1 items-center justify-end">
          <div className=" max-w-fit w-full hidden  px-14 h-4 md:flex   bg-colorPrimaryGold transform rotate-90 text-xs object-cover  text-center  items-end justify-end">
            <p className="   ">Video</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Books;
