import React from "react";
import VerificationInfo from "../components/VerificationInfo";

const VerificationPage = () => {
  return (
    <div>
      <div className="md:w-10/12 mx-auto w-full">
        <VerificationInfo />
      </div>
    </div>
  );
};

export default VerificationPage;
