import { Collapse, Select } from "antd";
import React from "react";
import Container from "./Container";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import { TbEdit } from "react-icons/tb";
import FindYourInterest from "./FindYourInterest";
import Students from "./Students";
import UpcomingEvents from "./UpcomingEvents";
const { Option } = Select;

const text = (
  <div>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
    <a href="" className="block">
      Create Videos for a YouTube Automation Channel
    </a>
  </div>
);

const itemsqwe = [
  {
    key: "1",
    label:
      "1 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "2",
    label:
      "2 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "3",
    label:
      "3 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "4",
    label:
      "4 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "5",
    label:
      "5 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "6",
    label:
      "6 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
  {
    key: "7",
    label:
      "7 What You'll Need to Create Videos for a YouTube Automation Channel?",
    children: <p>{text}</p>,
  },
];

const CreativeCanvaWorkshop = () => {
  return (
    <>
      <Container>
        <div>
          <img
            src="../../../images/canvaVideo.png"
            className="w-fit mx-auto"
            alt=""
          />
        </div>

        <div className="container pb-10 mx-auto mt-5 px-4">
          <div className="my-2 text-4xl font-bold">Creative Canva Workshop</div>
          <div>
            By Shubham Gill | Last Updated on{" "}
            <span className="font-bold">20-08-2023</span>
          </div>
          <p className="my-3">
            Starting from <span className="font-bold">₹540/-</span>
          </p>

          <div className="flex gap-3">
            <button className="bg-colorThemeBlue text-white text-sm py-2 px-3">
              Buy/Rent Now
            </button>
            <div className="flex gap-1 items-center text-colorThemeBlue font-bold text-sm">
              <PiTelegramLogoDuotone />
              <p>Share</p> |
            </div>
            <div className="flex gap-1 items-center text-colorThemeBlue font-bold text-sm">
              <TbEdit />
              <p>Reviews</p>
            </div>
          </div>

          <div className="border border-solid border-gray-300 bg-white rounded-lg py-4 px-8 my-7 ">
            <div className="text-2xl my-3">About Masterclass</div>
            <p className="text-md text-colorThemeBlue">
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.
            </p>
            <div className="flex my-3 gap-5 text-colorThemeBlue">
              <p>
                <span className="font-bold">Duration :</span> 42 minutes
              </p>
              <p>
                <span className="font-bold">Lorem : </span>42 minutes
              </p>
            </div>
          </div>

          <div className="grid grid-cols-5 md:gap-20 bg-white">
            <div className="col-span-5 md:col-span-2 ml-14 md:mb-20">
              <h1 className="text-2xl md:my-10 font-semibold">
                Course Benefits
              </h1>
              <ul className="list-disc mb-2 md:my-10 ">
                <li className="text-colorThemeBlue md:my-2">
                  Norem ipsum dolor sit amet, consectetur.
                </li>
                <li className="text-colorThemeBlue md:my-2">
                  Norem ipsum dolor sit amet, consectetur.
                </li>
                <li className="text-colorThemeBlue md:my-2">
                  Norem ipsum dolor sit amet, consectetur.
                </li>
                <li className="text-colorThemeBlue md:my-2">
                  Norem ipsum dolor sit amet, consectetur.
                </li>
              </ul>
            </div>
            <div className="col-span-5 md:col-span-3 md:mr-auto md:right-0">
              <img
                src="../../../images/canvaVideo.png"
                className="md:h-80 md:w-full "
                alt=""
              />
            </div>
          </div>

          <div>
            <div className="w-full block  border-r border border-l-0 border-t-0 border-b-0">
              <div className=" items-start justify-center ">
                <div className="mt-10">
                  <div className="border border-solid border-gray-300 shadow-md bg-white rounded-lg py-4 md:px-8 my-7">
                    <div className="block md:flex md:gap-4 items-center mb-3">
                      <h1 className="text-2xl font-normal">Course Content</h1>
                      <p>24 lessons | 3hr 20min</p>
                    </div>
                    <div>
                      <Collapse
                        accordion
                        items={itemsqwe}
                        defaultActiveKey={["1"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-3">
            <div className="col-span-5 md:col-span-2 lg:col-span-2">
              <img src="img.png" alt="" />
            </div>
            <div className="col-span-5 md:col-span-2 lg:col-span-3">
              <h1 className="text-3xl font-normal my-4 text-colorThemeBlue">
                Shubham Gill
              </h1>
              <strong>Charcoal Artist</strong>
              <p className="my-4">
                Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus.{" "}
              </p>
              <div className="text-lg font-semibold text-colorThemeBlue">
                Course Count: <span className="font-normal">5</span>
              </div>
            </div>
          </div>

          <div className="bg-white py-4 my-2">
            <div className="text-3xl my-5">Sneak Peak Images</div>
            <div>
              <FindYourInterest />
            </div>
          </div>

          <div>
            <Students />
          </div>

          <div>
            <h1 className=" container  mx-auto mb-7 px-4 text-xl md:text-3xl text-black font-normal ">
              More from the Author
            </h1>
            <UpcomingEvents />
          </div>

          <div>
            <h1 className=" container  mx-auto my-7 px-4 text-xl md:text-3xl text-black font-normal ">
              Similar
            </h1>
            <UpcomingEvents />
          </div>

          {/* <div className="flex">
            <div className="md:w-4/12 md:block hidden border-r border border-l-0 border-t-0 border-b-0">
              <div className="h-full flex items-start justify-center p-2">
                <div className="mt-10">
                  <div>
                    <Collapse
                      accordion
                      items={itemsqwe}
                      defaultActiveKey={["1"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-8/12 p-4">
              <img
                src="../../../images/canvaVideo.png"
                className="w-fit mx-auto"
                alt=""
              />
              <div className="border border-solid border-gray-300 bg-white rounded-lg p-4 mt-5">
                <div className="font-bold mb-3 text-lg">Transcription</div>
                Corem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Praesent
                auctor purus luctus enim egestas, ac scelerisque ante pulvinar.
                <br />
                <br />
                Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor
                urna. Curabitur vel bibendum lorem. Morbi convallis convallis
                diam sit amet lacinia. Aliquam in elementum tellus.
              </div>
            </div>
          </div> */}
        </div>
      </Container>
    </>
  );
};

export default CreativeCanvaWorkshop;
