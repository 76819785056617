import { Button, Form, message } from 'antd';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce'; 

function CreateEmail() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { Phone, countryCode, Otp } = location.state || {};

  const handleAccountAnalysis = (email) => {
    setError('');

    const postData = { email };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: `${process.env.REACT_APP_API}/user/email_available`,
      data: postData
    })
      .then(response => {
        const responseData = response?.data;
        message.success(responseData.Success);
      })
      .catch(err => {
        message.error(err?.response?.data?.Error);
      });
  };

  const handleSendOTP = () => {
    const postData = { email };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: `${process.env.REACT_APP_API}/user/send_email_otp`,
      data: postData
    })
      .then(response => {
        const responseData = response?.data;
        message.success(responseData.Success);
        navigate("/get_email", { state: { email:email, phone:Phone, country_code:countryCode, otp:Otp } });
      })
      .catch(err => {
        message.error(err?.response?.data?.Error);
      });
  };

  const handleSubmit = () => {
    handleSendOTP();
  };

  const debouncedHandleAccountAnalysis = useRef(
    debounce((email) => {
      handleAccountAnalysis(email);
    }, 500)
  ).current;

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const gmailDomain = "@gmail.com";
    if (emailValue.endsWith(gmailDomain) && emailRegex.test(emailValue)) {
      debouncedHandleAccountAnalysis(emailValue);
    }
  };

  return (
    <div className="modal-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 999 }}>
      <div className="modal-container border-8 border-t-colorPrimaryGold" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", padding: "20px", borderRadius: "8px", maxWidth: "400px", width: "80%" }}>
        <span style={{ float: "right", cursor: "pointer" }} onClick={() => navigate("/")}>X</span>
        <div className="flex justify-center">
          <h2 className="font-bold text-xl">Create your account</h2>
        </div>
        <p className='text-[#0007] text-sm text-center mt-2'>Hey there! You are one step closer from unlocking the best of learnings & events.</p>
        <Form onFinish={handleSubmit}>
          <div className="flex items-center border-b border-gray-300 border-b-colorThemeBlue px-3 py-2 mt-10 rounded-lg mb-3 border-2 w-full">
            <input
              type="email"
              placeholder="Email ID"
              value={email}
              onChange={handleEmailChange}
              className="border-none bg-transparent outline-none flex-grow"
            />
          </div>

          {error && <p className="text-red-500 text-sm mb-3">{error}</p>}
          <div className="flex justify-start mt-5">
            <Button type="primary" htmlType="submit" className="bg-colorPrimaryBlue text-white px-6 py-2 rounded-lg mb-6">Send OTP</Button>
          </div>
          <Link to="/login" className="mt-5 mb-10">Already a member? <span className="text-colorPrimaryBlue underline">Login</span></Link>
        </Form>
      </div>
    </div>
  );
}

export default CreateEmail;
