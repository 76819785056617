import React from "react";

const data = [
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },

  // Add more data objects as needed
];
const data2 = [
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and development",
    image: "https://placehold.co/800x400",
  },
  {
    id: 1,
    title: "Following the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and development",
    image: "https://placehold.co/800x400",
  },
 

  // Add more data objects as needed
];

const BasicTradingStratergy = () => {
  return (
    <>
    <a href = "/blog_inner">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 items-center">
        {data.map((item, index) => (
          <div key={item.id} className="relative">
            <img src={item.image} alt={item.title} className="object-contain relative z-0" />
            <div className="absolute bottom-0 top-0 left-0 right-0 bg-black/20"></div>
            <div className="absolute bottom-1 px-2 text-left z-20 text-white">
              {item.title}
            </div>
          </div>
        ))}
      </div>
      </a>
      <a href="/blog_inner">
            <div className="grid grid-cols-1 md:grid-cols-2 my-2 gap-4 items-center">
              {data2.map((item, index) => (
                <div key={item.id} className="relative">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="object-cover relative z-0"
                  />
                  <div className="absolute bottom-0 top-0 left-0 right-0 bg-black/20"></div>
                  <div className="absolute bottom-1 px-2 text-left z-20 text-white overflow-hidden">
                    {/* Limiting text to 3 lines and adding ellipsis for overflow */}
                    <div className="line-clamp-3">{item.title}</div>
                  </div>
                </div>
              ))}
            </div>
      </a>
      <div className="text-center">
        <a href="/blog"><div className="bg-colorThemeBlue text-white px-10 py-4 mt-5 w-fit mx-auto rounded-lg my-5 hover:bg-white hover:border-2 hover:border-colorThemeBlue hover:text-colorThemeBlue hover:font-semibold  text-lg">View All</div></a>
      </div>
    </>
  );
};

export default BasicTradingStratergy;
