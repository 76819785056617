import React, { useState } from "react";
import { Button, Drawer, Tabs } from "antd";
import Container from "../components/Container";
import Books from "../components/Books";
import BlogMain from "./BlogMain";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { MdVideoLibrary } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { IoSettings } from "react-icons/io5";
import { RiLogoutCircleLine } from "react-icons/ri";
import Notification from "../components/Notification";
import Collapse from "../components/Collapse";
import { Outlet, useLocation, useNavigate } from "react-router-dom";


const { TabPane } = Tabs;
const Homepage = () => {
  const [tabPosition, setTabPosition] = useState("left");
  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      key: "/dashboard/purchase",
      tab: (
        <div className="flex items-center gap-1">
          <BiSolidPurchaseTag />
          Purchase History
        </div>
      ),
    },
    {
      key: "/dashboard/video",
      tab: (
        <div className="flex items-center gap-1">
          <MdVideoLibrary />
          Video Stream
        </div>
      ),
    },
    {
      key: "/dashboard/notifications",
      tab: (
        <div className="flex items-center gap-1">
          <IoIosNotifications />
          Notifications
        </div>
      ),
    },
    {
      key: "/dashboard/settings",
      tab: (
        <div className="flex items-center gap-1">
          <IoSettings />
          Settings
        </div>
      ),
    },
    // {
    //   key: "/",
    //   tab: (
    //     <div onClick={handleLogout} className="flex items-center gap-1">
    //       <RiLogoutCircleLine />
    //       Log out
    //     </div>
    //   ),
    // },
  ];

  // const handleLogout = () => {
  //   localStorage.removeItem("jwt");
  //   window.location.reload();
  // };

  

  const handleTabChange = (key) => {
    navigate(key);
  };

  return (
    <>
      {/* <Container>
        <div>Homepage</div>
        <div className="container hidden lg:block my-10 ">
          <Tabs tabPosition={tabPosition} items={items} />
         
        </div>

        <div className="container block lg:hidden my-10 overflow-x-auto">
         
          <Tabs  items={items} />
         
        </div>
       </Container> */}
        <Container>
      <div className="md:mt-5 text-white">Homepage</div>
      <div className="md:my-8">
      <div className="container hidden lg:block md:my-10">
          <Tabs
            tabPosition="left"
            activeKey={location.pathname}
            onChange={handleTabChange}
          >
            {items.map((item) => (
              <TabPane tab={item.tab} key={item.key}>
                <Outlet />
              </TabPane>
            ))}
          </Tabs>
        </div>
        <div className="container block lg:hidden my-10 overflow-x-auto">
          <Tabs
          //  tabPosition="top"
            activeKey={location.pathname}
            onChange={handleTabChange}
          >
            {items.map((item) => (
               <TabPane tab={item.tab} key={item.key}>
               <Outlet />
             </TabPane>
            ))}
          </Tabs>
        </div>
        {/* <Outlet /> */}
      </div>
    </Container>
    </>
  );
};
export default Homepage;
