import { Select, message } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import JobList from "../components/JobList";
import Brands from "../components/Brands";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const Internship = () => {
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();


    const jobList = () => {
        axios({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          url: process.env.REACT_APP_CAREER + "job_list",
        })
          .then(async function (response) {
            const res = await response.data;
            if (res?.Success) {
              setUserData(res.Success);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.Error) {
              message.error(err.response.data.Error);
            }
          });
      };

      const ViewJob = (JobID) => {
       
        axios({
          method: "POST",
          headers: { "Content-Type": "application/json" },
          url: process.env.REACT_APP_CAREER + "view_job",
          data:{ id: JobID }
        })
          .then(async function (response) {
            const res = await response.data;
            if (res?.Success) {
                message.success(res.Success)
                navigate(`/blog_writer_form`, {
                    state: {
                        JobID: res.Success,
                    },
                  });
                // window.location.href = '/blog_writer_form'; 
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.Error) {
              message.error(err.response.data.Error);
            }
          });
      };

     
      useEffect(() => {
        jobList();
      }, []);

    return (
        <div className="container pb-10 mx-auto">
            <div>
                <div className="p-4">
                    <img
                        src="../../../images/internships.png"
                        className="w-full"
                        alt=""
                    />
                    <div className="text-2xl md:text-5xl mb-5 text-black font-semibold pt-16">
                        Job List
                    </div>
                    <div className="flex gap-3 mb-10">
                        <Search
                            placeholder="input search text"
                            style={{ width: 400 }}
                        />
                        <Select
                            placeholder="Category"
                            style={{ width: 200 }}
                            options={[
                                {
                                    value: "Category 1",
                                    label: "Category 1",
                                },
                                {
                                    value: "Category 2",
                                    label: "Category 2",
                                },
                                {
                                    value: "Category 3",
                                    label: "Category 3",
                                },
                                {
                                    value: "Category 4",
                                    label: "Category 4",
                                },
                            ]}
                        />
                    </div>

                    <JobList userData={userData} ViewJob={ViewJob} />
                    <div className="text-center mt-5">
                        <Brands />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Internship;
