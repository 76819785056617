import React from 'react'
import Container from './Container';
import Brands from './Brands';

function About() {
  return (
    <Container>
      <div className="container my-5">
        <div className="grid grid-cols-6">
          <div className="col-span-6 md:col-span-3 xl:col-span-2 mt-10 text-7xl">
            A Small Phrase
          </div>
          <div className="col-span-6 md:col-span-3 xl:col-span-3 mt-6">
            <h1 className="font-medium mt-4 mb-2 text-3xl">We are</h1>
            <p>
              A comprehensive platform that helps everyone who resides in the
              ecosystem. From students, and parents to principals, teachers,
              counselors and top management of schools, colleges and
              universities.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-6 mb-5 mt-8 gap-5">
          <div className="col-span-6 md:col-span-3 xl:col-span-4">
            <img
              src={"../../../images/about/Image1.png"}
              alt=""
              className="h-full  w-full"
            />
          </div>
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <img src={"../../../images/about/Image2.png"} alt="" className="h-full" />
          </div>
        </div>

        <div className="grid grid-cols-6  mt-8 ">
          <div className="col-span-6 md:col-span-3 xl:col-span-3 flex items-center">
            <div className='lg:w-3/4'>
            <h1 className="text-3xl lg:text-5xl text-colorThemeBlue my-4">Our Vision</h1>
            <p>
              To create a strong and trustworthy connection with students,
              schools, colleges, universities, educational professionals and
              parents for every educational and Career-related requirement.
            </p>
            </div>
          </div>
          <div className="col-span-6 md:col-span-3 xl:col-span-3">
            <img
              src={"../../../images/about/Image3.png"}
              alt=""
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-6 mb-5  mt-4 md:mt-0">
          <div className="col-span-6 md:col-span-3 xl:col-span-3">
            <img
              src={"../../../images/about/Image4.png"}
              alt=""
              className="w-full h-full"
            />
          </div>

          <div className="col-span-6 md:col-span-3 xl:col-span-3 flex items-center justify-center">
            <div className='lg:w-3/4 mx-2 lg:mx-0'>
            <h1 className="text-3xl lg:text-5xl text-colorThemeBlue my-4">Our Mission</h1>
            <p className='space-x-5'>
              Equip students with the knowledge, skills, & global perspective
              through a diverse range of programs, internships, & partnerships.
            </p>
            <p>
              Foster curiosity, critical thinking, & creativity that benefits
              both students and the collective growth of the school & college
              community.
            </p>
          </div>
          </div>
        </div>

        <div className="text-center mt-14">
          <Brands />
        </div>
      </div>
    </Container>
  );
}

export default About