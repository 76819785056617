import React from "react";

const PurchaseHistory = () => {
  return (
    <div>
      <div className="">
        Purchase history
      </div>
    </div>
  );
};

export default PurchaseHistory;
