import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import CreativeCanvaWorkshop from "./components/CreativeCanvaWorkshop";
import CreativeCanvaWorkshopSingle from "./components/CreativeCanvaWorkshopSingle";
import Layout from "./components/Layout";
import Congratulations from "./components/congratulations";
import Account from "./pages/Account";
import BlogWritersForm from "./pages/BWF";
import BlogInner from "./pages/BlogInner";
import BlogMain from "./pages/BlogMain";
import CBForm from "./pages/CBF";
import Cinema from "./pages/Cinema";
import Home from "./pages/Home";
import Internship from "./pages/Internship";
import InternshipForm from "./pages/InternshipForm";
import MasterClass from "./pages/MasterClass";
import Podcast from "./pages/Podcast";
import SurveyForm from "./pages/SurveyForm";
import VerificationPage from "./pages/VerificationPage";
import Webinar from "./pages/Webinar";
import WhatsNew from "./pages/WhatsNew";
import NewsMain from "./pages/NewsMain";
import NewsInner from "./pages/NewsInner";
import Login from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import Register from "./pages/Register";
import RegisterSec from "./pages/RegisterSec";
import About from "./components/About";
import Homepage from "./pages/Homepage";
import SecondTab from "./pages/SecondTab";
import TermsCondition from "./components/Terms&Condition";
import Policy from "./components/Policy";
import Refund from "./components/Refund";
import GetOtp from "./components/GetOtp";
import CreateOtp from "./components/CreateOtp";
import CreateEmail from "./components/CreateEmail";
import GetEmail from "./components/GetEmail";
import LoginForm from "./components/LoginForm";
import Books from "./components/Books";
import Notification from "./components/Notification";
import Collapse11 from "./components/Collapse";
import Settings from "./components/Settings";
import PrivateRoute from "./pages/ProtectRoute";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/register1" element={<Register />} />
          <Route exact path="/register2" element={<RegisterSec />} />
          <Route path="/" element={<Layout />}>
            <Route exact index element={<Home />} />
            <Route exact path="/cinema_23" element={<Cinema />} />
          
            <Route
              exact
              path="/verification_page"
              element={<VerificationPage />}
            />
            <Route
              exact
              path="/congratulations"
              element={<Congratulations />}
            />
            <Route exact path="/whatsnew" element={<WhatsNew />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/masterclass" element={<MasterClass />} />
            <Route exact path="/podcast" element={<Podcast />} />
            <Route exact path="/webinar" element={<Webinar />} />
            <Route exact path="/account" element={<Account />} />
            <Route exact path="/survey_form" element={<SurveyForm />} />
            <Route
              exact
              path="/creative_workshop"
              element={<CreativeCanvaWorkshop />}
            />
            <Route
              exact
              path="/creative_workshop_single"
              element={<CreativeCanvaWorkshopSingle />}
            />
            <Route
              exact
              path="/blog_writer_form"
              element={<BlogWritersForm />}
            />
            <Route exact path="/cb_form" element={<CBForm />} />
            <Route exact path="/internship_form" element={<InternshipForm />} />
            <Route exact path="/internship" element={<Internship />} />
            <Route exact path="/blog" element={<BlogMain />} />
            <Route exact path="/:slug" element={<BlogInner />} />
            <Route exact path="/news_main" element={<NewsMain />} />
            <Route exact path="/news_inner" element={<NewsInner />} />
            <Route exact path="/login" element={<LoginForm />} />
            <Route exact path="/register" element={<CreateOtp />} />
            <Route exact path="/get_otp" element={<GetOtp />} />
            <Route exact path="/email" element={<CreateEmail />} />
            <Route exact path="/get_email" element={<GetEmail />} />
            {/* <Route exact path="/homepage" element={<Homepage />} /> */}
       
        <Route path="/details" element={<SecondTab />} >
        <Route path="/details/terms-conditions" element={<TermsCondition />} />
        <Route path="/details/privacy-policy" element={<Policy />} />
        <Route path="/details/cancellation-refund" element={<Refund />} />
        </Route>

        {/* <Route exact path="/dashboard" element={<Homepage />} >
        <Route exact index element={<Books />} />
        <Route exact path="/dashboard/video" element={<Notification />} />
        <Route exact path="/dashboard/notifications" element={<Collapse11 />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        </Route> */}
  <Route element={<PrivateRoute />}>
<Route path="/dashboard" element={<Homepage />}>
        <Route path="purchase" element={<Books />} />
        <Route path="video" element={<Notification />} />
        <Route path="notifications" element={<Collapse11 />} />
        <Route path="settings" element={<div>Settings Component</div>} />
      </Route>
      </Route>
    

            <Route exact path="/forgetpassword" element={<ForgetPassword />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
