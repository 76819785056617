import {
  Breadcrumb,
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Tabs,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import axios from "axios";

const onChange = (key) => {
  console.log(key);
};

const BlogWritersForm = () => {
  const location = useLocation();
  const { JobID } = location.state;
  const [currentStep, setCurrentStep] = useState("1");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileVerificationRequired, setMobileVerificationRequired] = useState(false);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Simulate API response handling
    const res = {
      Success: {
        Mobile_Verification: JobID.Mobile_Verification,
        Email_Verification: JobID.Email_Verification,
        User_Details: JobID.Details,
      }
    };

    setMobileVerificationRequired(res.Success.Mobile_Verification);
    setEmailVerificationRequired(res.Success.Email_Verification);
    setMobileNumber(res.Success.User_Details.find(detail => detail.Parameter === "Contact Number")?.Value || "");
    setEmailId(res.Success.User_Details.find(detail => detail.Parameter === "Email")?.Value || "");

    let initialStep = "4"; 
    if (res.Success.Mobile_Verification) {
      initialStep = "2";
    } else if (res.Success.Email_Verification) {
      initialStep = "3";
    }
    setCurrentStep(initialStep);
  }, [JobID]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (currentStep === "4" && (mobileVerificationRequired || emailVerificationRequired)) {
      form.setFieldsValue({
        "Contact Number": mobileNumber,
        "Email": emailId
      });
    }
  }, [currentStep, mobileNumber, emailId, mobileVerificationRequired, emailVerificationRequired, form]);

  const handleNext = () => {
    setCurrentStep("4");
  };

  const handleNextstep = () => {
    setCurrentStep("2");
  };

  const handleFinalSubmit = (values) => {
    const payload = {
      id: JobID.JobID,
      field: {},
      filename: file ? file.name : "string", // Use file.name for the filename
    };
    
    Object.keys(values).forEach(key => {
      if (key !== 'Resume') {
        payload.field[key] = values[key];
      }
    });
  
    axios
      .post(process.env.REACT_APP_CAREER + "job_apply", payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        message.success(response.data.Success);
      })
      .catch((error) => {
        message.error(error.response.data.Error);
      });
  };
  
  

  const handleFinalSubmitddd = (values) => {
    const formData = new FormData();
    formData.append("id", JobID.JobID);
    const fieldObject = {};
    Object.keys(values).forEach(key => {
      if (key !== 'Resume') { // Assuming 'resume' is the key for the file input in the values
        fieldObject[key] = values[key];
      }
    });
  
    // Append the field object as a JSON string
    formData.append("field", JSON.stringify(fieldObject));
  

    if (file) {
      formData.append("file", file);
    } else {
      formData.append("file", "string"); 
    }
    

    // Send the API request using axios
    axios
      .post(process.env.REACT_APP_CAREER + "job_apply", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        // Handle successful response
        message.success(response.data.Success);
      })
      .catch((error) => {
        // Handle error response
        message.error(error.response.data.Error);
      });
  };

  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      setFile(info.file.originFileObj);
    }
  };


  const handleMobileVerificationSubmit = (values) => {
    setMobileNumber(values["Contact Number"]);
    if (emailVerificationRequired) {
      setCurrentStep("3");
    } else {
      setCurrentStep("4");
    }
  };

  const handleEmailVerificationSubmit = (values) => {
    setEmailId(values["Email"]);
    setCurrentStep("4");
  };

  const items = [
    {
      key: "1",
      label: "Job Description",
      children: (
        <div>
          <div className="my-2 text-xl md:text-4xl">Job Description</div>
          <div>
            <p>{JobID.Description}</p>
            <div className="mt-5 mb-2 font-semibold">Job Role</div>
            <ul className="list-disc md:ml-4">
              {JobID.Role.map((role, index) => (
                <li key={index}>{role}</li>
              ))}
            </ul>
            <div className="mt-5 mb-2 font-semibold">Requirements</div>
            <ul className="list-disc md:ml-4">
              {JobID.Requirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul>
          </div>
          <div className="flex justify-end">
          <Form.Item>
                <Button
                  className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                  type="primary"
                  onClick={handleNextstep}
                >
                  Submit
                </Button>
              </Form.Item>
          </div>
        </div>
      ),
    },
    ...(mobileVerificationRequired ? [
      {
        key: "2",
        label: "Mobile Verification",
        children: (
          <div>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleMobileVerificationSubmit}
              autoComplete="off"
            >
              <Form.Item
                name="Contact Number"
                label="Enter Mobile Number"
                rules={[
                  { required: true, message: "Please input your mobile number!" },
                  { pattern: /^[0-9]*$/, message: 'Please enter a valid mobile number!' },
                ]}
              >
                <InputNumber placeholder="Enter Mobile Number" className="w-[250px]" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        ),
      },
    ] : []),
    ...(emailVerificationRequired ? [
      {
        key: "3",
        label: "Email Verification",
        children: (
          <div>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleEmailVerificationSubmit}
              autoComplete="off"
            >
              <Form.Item
                name="Email"
                label="Enter Email ID"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: 'email', message: 'Please enter a valid email!' },
                  { validator: (_, value) => 
                      value.endsWith('@gmail.com') ? Promise.resolve() : Promise.reject('Email must end with @gmail.com!') 
                  },
                ]}
              >
                <Input placeholder="Enter Email ID" />
              </Form.Item>
              <Form.Item>
                <Button
                  className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        ),
      },
    ] : []),
    {
      key: "4",
      label: "User Details",
      children: (
        <div>
          <Form  form={form} layout="vertical"  onFinish={handleFinalSubmit}>
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1 ">
              {JobID.Details.map((detail, index) => (
                <Form.Item
                  key={index}
                  name={detail.Key}
                  label={detail.Key}
                  rules={[
                    { required: detail.Required, message: `Please input your ${detail.Key}!` },
                  ]}
                >
                  {detail.Type === "Text" ? (
                    <Input 
                      placeholder={`Enter ${detail.Key}`} 
                      readOnly={detail.Key === "Email" && emailVerificationRequired}
                      value={detail.Key === "Email" ? emailId : undefined} 
                    />
                  ) : detail.Type === "Options" ? (
                    <Input placeholder={`Select ${detail.Key}`} />
                  ) : detail.Type === "File" ? (
                    <Upload
                      onChange={handleFileChange}
                      beforeUpload={() => false}
                      accept=".pdf,.doc,.docx"
                    >
                      <Button icon={<UploadOutlined />}>
                        Click to Upload {detail.Key}
                      </Button>
                    </Upload>
                  ) : null}
                </Form.Item>
              ))}
            </div>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="text-base cursor-pointer w-fit  py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];

  return (
    <div className="container mx-auto pb-10">
      <div className="mb-5 pt-5 px-2 md:px-10">
        <Breadcrumb
          separator=">"
          items={[
            { title: "Homepage" },
            { title: "Internship" },
            { title: "Blog Writer Form" },
          ]}
        />
      </div>
      <div className="flex px-2 md:px-10">
        <div className="md:w-8/12 p-4 border-r border border-l-0 border-t-0 border-b-0">
          <div className="my-2 text-xl md:text-4xl">Blog Writer Form</div>
          <Tabs 
            activeKey={currentStep}
            onChange={onChange}
            items={items}
          />
        </div>
        <div className="md:w-4/12 md:block hidden ">
          <div className="h-full flex items-start justify-center">
            <div className="mt-10">
              <img
                src="../../../images/survey.png"
                className=" object-contain mx-auto p-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogWritersForm;
