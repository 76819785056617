import { message } from 'antd';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'

function GetEmail() {

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const location = useLocation();
    const [emailOtp, setEmailOtp] = useState('');

    const navigate = useNavigate();

  const {  email, phone, country_code, otp } = location.state || {};


    const closeLoginModal = () => {
        setLoginModalOpen(false);
        // setUsername("");
        // setPassword("");
      };

      const handleSendOTP = () => {
        const postData = { email };
    
        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: `${process.env.REACT_APP_API}/user/send_email_otp`,
          data: postData
        })
          .then(response => {
            const responseData = response?.data;
            message.success(responseData.Success);
          })
          .catch(err => {
            message.error(err?.response?.data?.Error);
          });
      };

      const handleOtpChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {
          setEmailOtp(value);
        }
      };

      const handleConfirm = (e) => {
        e.preventDefault();
        navigate("/register1", {state: { email:email,phone:phone, country_code:country_code, otp:otp,emailOtp:emailOtp }} );
      };

  return (
    <div>
      <div>
      <div className="modal-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 999 }}>
              
            <div className="modal-container border-8 border-t-colorPrimaryGold " style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", padding: "20px", borderRadius: "8px", maxWidth: "400px", width: "80%" }}>
                
              <span style={{ float: "right", cursor: "pointer" }} onClick={closeLoginModal}>X</span>
                <div className="flex justify-center">

                <h2 className="font-bold text-xl">OTP Verification</h2>

                </div>
                    <p className='mt-2  text-center'>OTP has been sent to {email}</p>
                <form onSubmit={handleConfirm}
                // onSubmit={handleSubmit}
                >
                   <input
                type="number"
                placeholder="6 digit OTP"
                value={emailOtp}
                maxLength="6"
                pattern="\d{6}"
                inputMode="numeric"
                onChange={handleOtpChange}
                className="border-b-colorThemeBlue px-3 py-2 mt-7 rounded-lg mb-3 border-2 w-full"
              />
              <p onClick={handleSendOTP} className="text-colorPrimaryBlue underline cursor-pointer">Resend</p>
                 
                  <div className="flex justify-start mt-5">
                  {/* <Link to={{ pathname: "/register1", state: { email:email,phone:phone, country_code:country_code, otp:otp,emailOtp:emailOtp } }}> */}
                  <button type="submit" className="bg-colorPrimaryBlue text-white px-6 py-2 rounded-lg mb-6">Confirm</button>
                  {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
      </div>
    </div>
  )
}

export default GetEmail
