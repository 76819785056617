import React from "react";

const data2 = [
    {
        id: 1,
        title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
        desc: "Following the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and development",
        name: "Aasha Pallav",
        image: "https://placehold.co/800x400",
    },
    {
        id: 1,
        title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
        desc: "Following the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and developmentFollowing the trend and development",
        name: "Aasha Pallav",
        image: "https://placehold.co/800x400",
    },

    // Add more data objects as needed
];

const BlogGrid2 = () => {
    return (
        <>
        <div className="container mx-auto my-4">
            <div className="text-3xl font-semibold mt-10">Latest Blog</div>
            <div className="text-md mt-2 mb-10">
                Read from our latest Blog and learn about the education industry
            </div>
            <a href='/blog_inner'>
            <div className="grid gris-cols-1 lg:grid-cols-2 my-2 gap-4 items-center">
                {data2.map((item, index) => (
                    <div
                        key={item.id}
                        className="relative rounded-lg overflow-hidden"
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="object-contain relative z-0"
                        />
                        <div className="absolute bottom-0 top-0 left-0 right-0 bg-black/20"></div>
                        <div className="absolute bottom-1 px-2 text-left z-20 text-white">
                            <div className="text-xl font-semibold">{item.title}</div>
                            <div className="text-sm my-2">{item.desc}</div>
                            <div className="text-sm">{item.name}</div>
                        </div>
                    </div>
                ))}
            </div>
            </a>
            </div>
        </>
    );
};

export default BlogGrid2;
