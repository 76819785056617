import React from "react";

function TermsCondition() {
  return (
    <div className="text-justify">
   
      {/* <div className="container mx-auto "> */}
        <h1 className="text-xl md:text-3xl my-4 font-bold tracking-wide text-gray-700 transition-colors duration-200">
          Terms &amp; Conditions
        </h1>
        <p className="text-black font-semibold my-4">
          Last updated on Oct 10th 2023
        </p>
        <p>
          The Website Owner, including subsidiaries and affiliates (“Website” or
          “Website Owner” or “we” or “us” or “our”) provides the information
          contained on the website or any of the pages comprising the website
          (“website”) to visitors (“visitors”) (cumulatively referred to as
          “you” or “your” hereinafter) subject to the terms and conditions set
          out in these website terms and conditions, the privacy policy and any
          other relevant terms and conditions, policies and notices which may be
          applicable to a specific section or module of the website.
        </p>
        <br />
        <p>
          Welcome to our website. If you continue to browse and use this website
          you are agreeing to comply with and be bound by the following terms
          and conditions of use, which together with our privacy policy govern
          Vigor LaunchPad''s relationship with you in relation to this website.
        </p>
        <p className="my-4">
          The term 'Vigor LaunchPad' or 'us' or 'we' refers to the owner of the
          website whose registered/operational office is 4th Floor, Unit No 3,
          Samruddhi Venture Park, MIDC, Andheri East Mumbai MAHARASHTRA 400093.
          The term 'you' refers to the user or viewer of our website.
        </p>
        <strong className="text-2xl">
          The use of this website is subject to the following terms of use:
        </strong>
        <ul className="list-disc ml-5" >
          <li className="my-2 list-decimal">
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </li>
          <li className="my-2">
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </li>
          <li className="my-2">
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </li>
          <li className="my-2">
            This website contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </li>
          <li className="my-2">
            All trademarks reproduced in this website which are not the property
            of, or licensed to, the operator are acknowledged on the website.
          </li>
          <li className="my-2">
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </li>
          <li className="my-2">
            From time to time this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information.
          </li>
          <li className="my-2">
            You may not create a link to this website from another website or
            document without Vigor LaunchPad’s prior written consent.
          </li>
          <li className="my-2">
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of India or other regulatory
            authority.
          </li>
          <div className="my-2">
            We as a merchant shall be under no liability whatsoever in respect
            of any loss or damage arising directly or indirectly out of the
            decline of authorization for any Transaction, on Account of the
            Cardholder having exceeded the preset limit mutually agreed by us
            with our acquiring bank from time to time
          </div>
        </ul>
        <strong className="text-2xl">Registration Obligation</strong>
        <ul className="list-disc ml-5" >
          <li className="my-2 list-decimal">
            You are responsible for maintaining the confidentiality of your User
            ID and Password. You are responsible for all the activities that
            occur under your User ID and Password. You agree, inter-alia, to;
          </li>
          <li className="my-2">
            Provide true, accurate, current and complete information about
            yourself as prompted by CareerBeam registration form (both personal
            and professional information), maintain and promptly update the
            Registration Data to keep it true, accurate, current, and complete
            at all times; - You must immediately notify us of any unauthorized
            use of your password or account or any other breach of security,
            and; ensure that you exit from your account at the end of each
            session.
          </li>
          <li className="my-2">
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete or CareerBeam has reasonable grounds to
            suspect that such information is untrue, inaccurate, not current or
            incomplete, or not in accordance with this Agreement, CareerBeam has
            the right to indefinitely suspend or terminate or block access of
            your registration with the website.
          </li>
          <li className="my-2">
            CareerBeam shall not be liable for any loss or damage arising from
            your failure to comply with this Clause.
          </li>
        </ul>
        <strong className="text-lg md:text-2xl">Information Collected by the Website:</strong>
        <ul className="list-disc ml-5">
          <li className="my-2 list-decimal">
            Means of data collection on CareerBeam are- Contact Forms (The
            contact forms on the website are made to simplify the process of
            data presentation by collecting the user interests, educational
            qualification and age, etc), and Login/Signup By creating a user
            account visitor agrees the terms of conditions of the website. The
            data collected may consist of personal or non-personal information.
            We may store, collect, use and process your Information subject to
            compliance under applicable laws in India. The website collects user
            information to provide suggestions tailored as per the students'
            profile and keep them informed about the ongoing application
            processes, upcoming events and education sector news.
          </li>
        </ul>
      </div>
    // </div>
  );
}

export default TermsCondition;
