import React, { useEffect, useState } from "react";
import { BiMenuAltRight, BiX } from "react-icons/bi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Dropdown1 from "./Dropdown";
import { RiFacebookLine } from "react-icons/ri";
import { VscMail } from "react-icons/vsc";
import { IoLogoInstagram } from "react-icons/io";
import { FaTwitter } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { Button, Divider, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, theme } from "antd";
import MyAccountDropdown from "./MyAccountDropdown";
const { useToken } = theme;
const items = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
  {
    key: "3",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item (disabled)
      </a>
    ),
    disabled: true,
  },
];

const Layout = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [navbarBg, setNavbarBg] = useState("#ffffff");
  const location = useLocation();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isWebinarDropdownOpen, setWebinarDropdownOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const jwt = localStorage.getItem("jwt");

  console.log(jwt, "jjjjjj");

  const toggleDropdown = () => {
    setMobileOpen(!mobileOpen);
  };

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    setUsername("");
    setPassword("");
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Username:", username);
    console.log("Password:", password);
    setUsername("");
    setPassword("");
    closeLoginModal();
  };

  const isActive = (path) => {
    return location.pathname === path ? "text-blue-500" : "";
  };

  useEffect(() => {
    const handleScroll = () => {
      setNavbarBg(window.scrollY > 10 ? "#fff" : "#fff");
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleForgetPassword = () => {
    closeLoginModal();
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
    setWebinarDropdownOpen(false);
  };

  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);

  const toggleAccountDropdown = () => {
    setIsAccountDropdownOpen(!isAccountDropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("jwt");
    window.location.reload();
  };

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <>
      <nav
        id="navbar"
        className={`navbar flex-wrap items-center justify-between   py-3 w-full mb-0 fixed top-0 bg-white z-50 shadow-[0_0_6px_#92bde0] ${
          isWebinarDropdownOpen
            ? "h-48 md:h-[330px] lg:h-300px"
            : navbarOpen
            ? "h-screen"
            : "h-auto"
        }`}
        // style={{ backgroundColor: navbarBg }}
      >
        <div className="px-4  lg:px-0 flex flex-wrap items-center justify-between w-full ">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <img
              src="../../../images/cb_logo.png"
              alt=""
              className="w-32 lg:hidden block"
            />
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border-solid border-2 rounded bg-colorThemeBlue block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => {
                setNavbarOpen(!navbarOpen);
                if (navbarOpen) {
                  setWebinarDropdownOpen(false);
                }
              }}
            >
              {navbarOpen ? (
                <BiX className="text-white" />
              ) : (
                <BiMenuAltRight className="text-white" />
              )}
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow justify-between items-center flex-col lg:flex-row gap-5" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <div
              className={`absolute bg-white shadow-2xl w-full duration-300 hidden block mt-40 lg:flex flex-grow justify-between items-center flex-col lg:flex-row  items-center`}
            >
              <div className="ml-40 mt-40 ">
                <div className="text-black font-bold text-4xl my-5">
                  Join our <br></br>registration list{" "}
                </div>
                <div className="mb-5">
                  And receive updates, expert educational <br></br> insights,
                  and early access to our platform.
                </div>
                <div className="flex gap-5 my-5">
                  <button className="bg-colorThemeBlue text-white px-4 py-2 rounded-full ">
                    Join
                  </button>
                  <ul className="flex flex-row gap-2 items-center">
                    <li>
                      <RiFacebookLine
                        size={30}
                        className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                      />
                    </li>
                    <li>
                      <VscMail
                        size={30}
                        className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                      />
                    </li>
                    <li>
                      <IoLogoInstagram
                        size={30}
                        className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                      />
                    </li>
                    <li>
                      <FaTwitter
                        size={30}
                        className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                      />
                    </li>
                    <li>
                      <FaWhatsapp
                        size={30}
                        className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div className=" mt-[10%] mx-6">
                <img
                  src={"../../../images/banner.png"}
                  className="w-[500px] h-auto"
                  alt=""
                />
              </div>
            </div>
            <Link to="/" className="hidden lg:block mx-8">
              <img
                src="../../../images/cb_logo.png"
                alt=""
                className="w-26 mt-2 relative z-10"
              />
            </Link>
            <ul className="flex relative items-start mx-5 md:items-center flex-col lg:flex-row list-none mx-12 w-full md:gap-5 gap-3 lg:w-auto text-end md:border-none md:pt-0 pt-10 md:mt-0 mt-2 border-t-2">
              <li
                className={`md:text-unset text-center font-normal ${isActive(
                  "/whatsnew"
                )}`}
              >
                <Link to="/whatsnew" onClick={closeNavbar}>
                  What's new
                </Link>
              </li>
              <div className="border-[#ccc6] border-[1px]  w-full md:hidden"></div>

              <li
                className={`md:text-unset text-center font-normal relative hidden md:block`}
              >
                <div className="flex items-center">
                  <span
                    onClick={() =>
                      setWebinarDropdownOpen(!isWebinarDropdownOpen)
                    }
                    className={`mx-2 cursor-pointer ${
                      isWebinarDropdownOpen ? "text-colorThemeBlue" : ""
                    }`}
                  >
                    Knowledge Hub
                  </span>
                  <Dropdown1
                    isOpen={isWebinarDropdownOpen}
                    toggleDropdown={() =>
                      setWebinarDropdownOpen(!isWebinarDropdownOpen)
                    }
                  />
                </div>
              </li>

              <li
                className={`md:text-unset text-start font-normal relative block flex justify-start md:hidden`}
              >
                <div className="">
                  <span
                    onClick={toggleDropdown}
                    className={`cursor-pointer ${
                      mobileOpen ? "text-colorThemeBlue" : ""
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      Knowledge Hub
                      {mobileOpen ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                  </span>
                  {/* Yaha pe aapka dropdown content aayega */}
                  {mobileOpen && (
                    <div className="dropdown-content">
                      <div>
                        <ul className="list-disc mx-4">
                          <li
                            className={`md:text-unset text-start  my-1 font-normal text-base ${isActive(
                              "/masterclass"
                            )}`}
                          >
                            <Link to="/masterclass">Masterclass</Link>
                          </li>
                          <li
                            className={`md:text-unset text-start  my-1 font-normal text-base ${isActive(
                              "/webinar"
                            )}`}
                          >
                            <Link to="/webinar">Webinar</Link>
                          </li>

                          <li
                            className={`md:text-unset text-start  my-1 font-normal text-base ${isActive(
                              "/podcast"
                            )}`}
                          >
                            <Link to="/podcast">Podcast</Link>
                          </li>
                          <li
                            className={`md:text-unset text-start  my-1 font-normal text-base ${isActive(
                              "/blog"
                            )}`}
                          >
                            <Link to="/blog">Blogs</Link>
                          </li>
                          {/* Add more webinar links as needed */}
                        </ul>
                        <div>
                          <img
                            src={"../../../images/banner.png"}
                            className="w-full h-auto"
                            alt=""
                          />
                        </div>
                        <div>
                          <h1 className="font-bold text-lg mt-2">
                            Join our registration list
                          </h1>
                          <p className="text-xs">
                            And receive updates, expert educational insights,
                            and early access to our platform.
                          </p>
                        </div>
                        <div>
                          <div className="flex gap-5 my-5 items-center justify-center">
                            <button className="bg-colorThemeBlue text-white px-4 py-2 rounded-full ">
                              Join
                            </button>
                            <ul className="flex flex-row gap-2 items-center">
                              <li>
                                <RiFacebookLine
                                  size={30}
                                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                                />
                              </li>
                              <li>
                                <VscMail
                                  size={30}
                                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                                />
                              </li>
                              <li>
                                <IoLogoInstagram
                                  size={30}
                                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                                />
                              </li>
                              <li>
                                <FaTwitter
                                  size={30}
                                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                                />
                              </li>
                              <li>
                                <FaWhatsapp
                                  size={30}
                                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </li>
              <div className="border-[#ccc6] border-[1px]  w-full md:hidden"></div>
              <li
                className={`md:text-unset text-center font-normal ${isActive(
                  "/news_main"
                )}`}
              >
                <Link to="/news_main" onClick={closeNavbar}>
                  CB News
                </Link>
              </li>
              <div className="border-[#ccc6] border-[1px]  w-full md:hidden"></div>
              <li
                className={`md:text-unset text-center font-normal ${isActive(
                  "/internship"
                )}`}
              >
                <Link to="/internship" onClick={closeNavbar}>
                  Internship
                </Link>
              </li>
              <div className="border-[#ccc6] border-[1px]  w-full md:hidden"></div>

              <li
                className={`md:text-unset text-center font-normal ${isActive(
                  "/about"
                )}`}
              >
                <Link to="/about" onClick={closeNavbar}>
                  About Us
                </Link>
              </li>
              <div className="border-[#ccc6] border-[1px]  w-full md:hidden"></div>
            </ul>
            {/* <Navlink/> */}
            <div className="md:flex items-center mr-5  hidden">
              {jwt ? (
                <MyAccountDropdown
                  toggleAccountDropdown={toggleAccountDropdown}
                  isAccountDropdownOpen={isAccountDropdownOpen}
                  handleLogout={handleLogout}
                />
              ) : (
                <Link
                  to="/login"
                  // onClick={openLoginModal}
                  className="text-base relative py-4 px-10 text-black font-bold rounded-lg hover:text-second"
                >
                  Login
                </Link>
              )}

              <Link
                to="/register"
                className="text-base relative py-2 mt-2 px-10 bg-colorThemeBlue rounded-3xl text-white "
              >
                Register Now
              </Link>
            </div>

            <div className="border-[#ccc6] border-[1px] bottom-20 fixed w-full md:hidden"></div>
            <div className="flex w-full items-center my-4  ml-auto justify-center gap-3 bottom-0 fixed  md:hidden">
              {/* <Link
                to="/login"
                // onClick={openLoginModal}
                className="text-base relative border-[1px] border-colorThemeBlue  py-1 rounded-full px-10 text-black font-bold  hover:text-second"
              >
                Login
              </Link> */}

              {jwt ? (
                <MyAccountDropdown
                  toggleAccountDropdown={toggleAccountDropdown}
                  isAccountDropdownOpen={isAccountDropdownOpen}
                  handleLogout={handleLogout}
                />
              ) : (
                <Link
                  to="/login"
                  // onClick={openLoginModal}
                  className="text-base relative py-4 px-10 text-black font-bold rounded-lg hover:text-second"
                >
                  Login
                </Link>
              )}

              <Link
                to="/register"
                className="text-base relative py-2  px-10 bg-colorThemeBlue rounded-3xl text-white "
              >
                Register Now
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="mt-20 bg-slate-50">
        <Outlet />
      </div>
      <>
        <section className="relative pt-16 pb-5 bg-gray-100 lg:h-84 lg:px-5 px-3">
          <div className="container mx-auto block">
            <div className="flex flex-col lg:flex-row lg:justify-between px-6 justify-center items-center ">
              <div className="lg:2/12 w-full mb-4 md:mb-0">
                <img
                  src="../../../images/cb_logo.png"
                  alt=""
                  className="w-32"
                />
              </div>

              <div className="4/12 w-full">
                <div className="grid grid-cols-2 gap-4">
                  <Link to="/webinar">
                    <div>Webinar</div>
                  </Link>
                  <Link to="/blog">
                    <div>Blogs</div>
                  </Link>
                  <Link to="/podcast">
                    <div>Podcast</div>
                  </Link>
                  <Link to="/news_main">
                    {" "}
                    <div>News</div>
                  </Link>
                  <Link to="/masterclass">
                    <div>Masterclass</div>
                  </Link>
                  <Link to="/internship">
                    <div>Internship</div>
                  </Link>
                  <Link to="/about">
                    <div>About Us</div>
                  </Link>
                  <Link to="/survey_form">
                    <div>Survey</div>
                  </Link>
                </div>
              </div>
              <div className="2/12 w-full my-4 md:my-0">
                <div className="grid grid-cols-1 gap-4">
                  <Link to="/details/terms-conditions">
                    <div>Terms & Conditions</div>
                  </Link>
                  <Link to="/details/cancellation-refund">
                    <div> Cancellation & Refund Policy</div>
                  </Link>
                  <Link to="/details/privacy-policy">
                    <div>Privacy Policy</div>
                  </Link>
                </div>
              </div>
              <div className="lg:4/12 w-full">
                <h6 className="font-bold mt-3 md:mt-0">Join our community</h6>
                <form className=" mx-auto rounded pt-6 pb-8 mb-4">
                  <div className="mb-4 flex -gap-1">
                    <input
                      className="appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline primary-color"
                      id="email"
                      type="email"
                      placeholder="Email Address"
                    />
                    <button
                      className="bg-primary-color bg-colorPrimaryBlue block  hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      type="submit"
                    >
                      Go
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="border-[1px] border-[#ccc] my-5"></div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-center mx-10">
            <div className="md:text-base text-sm my-4 md:mt-0">
              2024 CareerBeam. All rights reserved
            </div>
            <ul className="flex flex-row gap-2 items-center">
              <li>
                <RiFacebookLine
                  size={30}
                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                />
              </li>
              <li>
                <VscMail
                  size={30}
                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                />
              </li>
              <li>
                <IoLogoInstagram
                  size={30}
                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                />
              </li>
              <li>
                <FaTwitter
                  size={30}
                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                />
              </li>
              <li>
                <FaWhatsapp
                  size={30}
                  className="rounded-full border-2 border-black p-1 hover:bg-black hover:text-white"
                />
              </li>
            </ul>
          </div>
        </section>
      </>
    </>
  );
};

export default Layout;
