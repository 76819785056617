import React from 'react';
import { Collapse, Select } from "antd";

function Collapse11() {

    const text = (
        <div>
          <a href="" className="block">
          Korem ipsum dolor sit amet, consectetur adipiscing elit.
          </a>
          <a href="" className="block">
          Korem ipsum dolor sit amet, consectetur adipiscing elit.
          </a>
          <a href="" className="block">
          Korem ipsum dolor sit amet, consectetur adipiscing elit.
          </a>
        </div>
      );

    const itemsqwe = [
        {
          key: "1",
          label:
            "Email Notifications",
          children: <p>{text}</p>,
        },
        {
          key: "2",
          label:
            " WhatsApp Notifications",
          children: <p>{text}</p>,
        },
        {
          key: "3",
          label:
            " General Notifications",
          children: <p>{text}</p>,
        },
      ];
    return (
        <div>
            <div>
            <div className="w-full block  border-r border border-l-0 border-t-0 border-b-0">
              <div className=" items-start justify-center ">
                <div className="mt-10">
                  <div className="border border-solid border-gray-300 shadow-md bg-white rounded-lg py-4 md:px-8 my-7">
                    
                    <div>
                      <Collapse
                        accordion
                        items={itemsqwe}
                        defaultActiveKey={["1"]}
                      />
                    </div>
                  </div>
                  <div>
                    <button className="bg-colorThemeBlue text-white py-3 px-5">Apply Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
    );
}

export default Collapse11;