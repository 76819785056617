import React from "react";

const data = [
  {
    id: 1,
    title:
      "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title:
      "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title:
      "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },
  {
    id: 1,
    title:
      "Following the trend and developmentFollowing the trend and developmentFollo",
    image: "https://placehold.co/400x400",
  },

  // Add more data objects as needed
];

const BasicTradingStratergyGid4 = () => {
  return (
    <>
    <a href="/news_inner">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
        {data.map((item, index) => (
          <div key={item.id} className="relative">
            <img
              src={item.image}
              alt={item.title}
              className="object-contain relative z-0"
            />
            <div className="absolute bottom-0 top-0 left-0 right-0 bg-black/20"></div>
            <div className="absolute bottom-1 px-2 text-left z-20 text-white">
              {item.title}
            </div>
          </div>
        ))}
      </div>
      </a>
    </>
  );
};

export default BasicTradingStratergyGid4;
