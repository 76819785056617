import { Pagination, Select } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import ListLayout from "../components/ListLayout";

const NewsInner = () => {
    return (
        <>
            <div className="bg-gradient-to-b from-gray-50 via-gray-50 to-blue-100">
                <div className=" container mx-auto pt-10 pb-10">
                    <div className="flex juistify-center items-start gap-4">
                        <div className="w-3/4">
                            <div className="bg-white shadow-md p-4  rounded-md">
                                <img
                                    src="../../../images/newsInner.png"
                                    className="w-[600px] mx-auto mb-10"
                                    alt=""
                                />
                                <div className="">
                                    <div className="font-semibold text-2xl">
                                        Dorem Nunc vulputate libero et velit
                                        interdum,
                                        <br /> ac aliquet odio mattis.
                                    </div>
                                    <div className="flex gap-5 mb-10 mt-5">
                                        <div className="">By CareerBeam</div>
                                        <div className="">
                                            24th Sept, 2023
                                        </div>{" "}
                                        <div className="underline transition-all transition-5s cursor-pointer hover:font-semibold">
                                            {" "}
                                            Share
                                        </div>
                                    </div>
                                </div>

                                <div className="my-10">
                                    <div className="text-xl">
                                        Corem ipsum dolor sit amet, consectetur
                                        adipiscing elit. Etiam eu turpis
                                        molestie, dictum est a, mattis tellus.
                                        Sed dignissim, metus nec fringilla
                                        accumsan, risus sem sollicitudin lacus,
                                        ut interdum tellus elit sed risus.
                                        Maecenas eget condimentum velit, sit
                                        amet feugiat lectus. Class aptent taciti
                                        sociosqu ad litora torquent per conubia
                                        nostra, per inceptos himenaeos. Praesent
                                        auctor purus luctus enim egestas, ac
                                        scelerisque ante pulvinar.
                                    </div>
                                    <div className="text-xl mt-4">
                                        Donec ut rhoncus ex. Suspendisse ac
                                        rhoncus nisl, eu tempor urna. Curabitur
                                        vel bibendum lorem. Morbi convallis
                                        convallis diam sit amet lacinia. Aliquam
                                        in elementum tellus.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/4 h-full">
                            <div className="bg-white shadow-md p-4 rounded-md h-full">
                                <img
                                    src="../../../images/survey.png"
                                    className="w-full"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-md mt-16 shadow-lg">
                        <div className="text-4xl mb-5 text-black font-semibold ">
                            Blog Posts
                        </div>
                        <div className="flex gap-3 mb-10">
                            <Search
                                placeholder="input search text"
                                style={{ width: 400 }}
                            />
                            <Select
                                placeholder="Category"
                                style={{ width: 200 }}
                                options={[
                                    {
                                        value: "Category 1",
                                        label: "Category 1",
                                    },
                                    {
                                        value: "Category 2",
                                        label: "Category 2",
                                    },
                                    {
                                        value: "Category 3",
                                        label: "Category 3",
                                    },
                                    {
                                        value: "Category 4",
                                        label: "Category 4",
                                    },
                                ]}
                            />
                        </div>
                        <ListLayout />
                        <div className="text-center pb-10">
                            <Pagination defaultCurrent={1} total={50} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsInner;
