import { Pagination, Select } from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect } from "react";
import BlogGrid3 from "../components/BlogGrid3";
import { useLocation } from "react-router-dom";
import { PiTelegramLogoDuotone } from "react-icons/pi";

const BlogInner = () => {
  const location = useLocation();
  const { htmlCode, blog, Author, Created_On } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!location.state) {
    return <div>Error: No blog data available.</div>;
  }

  return (
    <>
      <div className="grid lg:grid-cols-6 gap-4">
        <div className="col-span-6 lg:col-span-4 my-4 container mt-10 ">
          <div className="px-2 lg:px-10 py-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-black text-lg my-4 font-bold text-center">
              {blog}
            </h1>
            <div className="flex justify-center items-center gap-4 mb-5">
              <div className="font-bold text-sm">By {Author}</div>
              <div className="font-bold text-sm">{Created_On}</div>
              <div className="flex items-center gap-1 font-bold text-sm">
                <PiTelegramLogoDuotone />
                Share
              </div>
            </div>

            <div dangerouslySetInnerHTML={{ __html: htmlCode }}></div>
          </div>
        </div>

        <div className="col-span-6 lg:col-span-2">
          <img src="../../../images/survey.png" className="w-full" alt="" />
        </div>
      </div>

      <div></div>
    </>
  );
};

export default BlogInner;
