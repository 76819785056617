import React from "react";

function Policy() {
  return (
    <div className="text-justify">
        <h1 class="text-3xl my-4 font-bold tracking-wide text-gray-700 transition-colors duration-200">
          Privacy Policy
        </h1>
        <p class="text-black font-semibold my-4">
          Last updated on Oct 10th 2023
        </p>
        <p>
          This privacy policy sets out how Vigor LaunchPad uses and protects any
          information that you give Vigor LaunchPad when you use this website.
        </p>
        <br />
        <p>
          Vigor LaunchPad is committed to ensuring that your privacy is
          protected. Should we ask you to provide certain information by which
          you can be identified when using this website, and then you can be
          assured that it will only be used in accordance with this privacy
          statement.
        </p>
        <p class="my-4">
          Vigor LaunchPad may change this policy from time to time by updating
          this page. You should check this page from time to time to ensure that
          you are happy with any changes.
        </p>
        <strong class="text-2xl">
          We may collect the following information:
        </strong>
        <ul class="list-disc ml-5">
          <li class="my-2 list-decimal">Name and job title</li>
          <li class="my-2">Contact information including email address</li>
          <li class="my-2">
            Demographic information such as postcode, preferences and interests
          </li>
          <li class="my-2">
            Other information relevant to customer surveys and/or offers
          </li>
        </ul>
        <strong class="text-2xl">
          What we do with the information we gather
        </strong>
        <div>
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </div>
        <ul class="list-disc ml-5">
          <li class="my-2">Internal record keeping.</li>
          <li class="my-2">
            We may use the information to improve our products and services.
          </li>
          <li class="my-2">
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided.
          </li>
        </ul>
        <div class="my-2">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclosure we have put in suitable
          measures.
        </div>
        <strong>How we use cookies</strong>
        <div class="my-2">
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyses web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </div>
        <div class="my-2">
          We use traffic log cookies to identify which pages are being used.
          This helps us analyses data about webpage traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </div>
        <div class="my-2">
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </div>
        <div class="my-2">
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website.
        </div>
        <strong>Controlling your personal information</strong>
        <div class="my-2">
          You may choose to restrict the collection or use of your personal
          information in the following ways:
        </div>
        <ul class="list-disc ml-5">
          <li class="my-2">
            whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes
          </li>
          <li class="my-2">
            if you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at rushikesh@vigorlaunchpad.com
          </li>
        </ul>
        <div class="my-2">
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </div>
        <div class="my-2">
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </div>
        <strong>Analytics</strong>
        <ul class="list-disc ml-5">
          <li class="my-2">
            We use Google Analytics to track the user behavior. We also check
            which pages they are visiting.
          </li>
          <li class="my-2">
            You can check out Google Analytics Privacy Policy.
          </li>
        </ul>
        <strong>We share your data with...</strong>
        <ul class="list-disc ml-5">
          <li class="my-2">
            Whenever a user registers to our website we collect user data such
            as Name, Number &amp; Email address. We save that data in our CRM
            systems. The data we collect will be used for CareerBeam and will be
            distributed to our partners who have participated in our event.
          </li>
          <li class="my-2">
            We also use that data to target Facebook, Google  Linkedin ads
            and also to share a better experience to every user.{" "}
          </li>
        </ul>
      </div>
  );
}

export default Policy;
