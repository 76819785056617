import { Button, Form, message } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

const countryCodes = [
  { code: '+1', country: 'USA' },
  { code: '+91', country: 'India' },
  { code: '+65', country: 'Singapore' },
  // Add more country codes as needed
];

function CreateOtp() {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAccountAnalysis = (completePhoneNumber) => {
    setError(''); // Clear any previous errors
    if (!countryCode) {
      setError('Please select a country code.');
      return;
    }

    const postData = { country_code: countryCode, phone: completePhoneNumber };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: `${process.env.REACT_APP_API}/user/phone_number_available`,
      data: postData
    })
    .then(response => {
      const responseData = response?.data;
      message.success(responseData.Success);
    })
    .catch(err => {
      message.error(err?.response?.data?.Error);
    });
  };

  const handleSendOTP = (completePhoneNumber) => {
    const postData = { country_code: countryCode, phone: phone };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: `${process.env.REACT_APP_API}/user/send_mobile_otp`,
      data: postData
    })
    .then(response => {
      const responseData = response?.data;
      message.success(responseData.Success);
      navigate("/get_otp", { state: { phone: phone, country_code: countryCode } });
    })
    .catch(err => {
      message.error(err?.response?.data?.Error);
    });
  };

  const handleSubmit = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (phoneRegex.test(phone)) {
      handleAccountAnalysis();
    } else {
      setError('Please enter a valid 10-digit phone number.');
    }
  };

  // const handlePhoneChange = (e) => {
  //   const { value } = e.target;
  //   // Update phone state
  //   setPhone(value);

  //   // Check if phone number is valid and call handleSubmit
  //   const phoneRegex = /^[0-9]{10}$/;
  //   if (phoneRegex.test(phone)) {
  //     handleAccountAnalysis();
  //   } else {
  //     setError('Please enter a valid 10-digit phone number.');
  //   }
  // };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
  
    const numericValue = value.replace(/\D/g, ''); 
  
    setPhone(e.target.value);
  
    if (/^\d{10}$/.test(numericValue)) {
      const completePhoneNumber = numericValue;
      handleAccountAnalysis(completePhoneNumber);
    } else {
      setError('');
    }
  };
  
  const closeLoginModal = () => {
    setLoginModalOpen(false);
    // setUsername("");
    // setPassword("");
  };

 

  return (
    <>
    <div>
      <div className="modal-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 999 }}>
        <div className="modal-container border-8 border-t-colorPrimaryGold" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", padding: "20px", borderRadius: "8px", maxWidth: "400px", width: "80%" }}>
          <span style={{ float: "right", cursor: "pointer" }} onClick={() => navigate("/")}>X</span>
          <div className="flex justify-center">
            <h2 className="font-bold text-xl">Create your account</h2>
          </div>
          <p className='text-[#0007] text-sm text-center mt-2'>Hey there! You are one step closer from unlocking the best of learnings & events.</p>
          <Form onFinish={handleSendOTP}>
          <div className="flex items-center border-b border-gray-300 border-b-colorThemeBlue px-3 py-2 mt-10 rounded-lg mb-3 border-2 w-full">
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="border-none bg-transparent outline-none mr-2"
              >
                <option value="">Select</option>
                {countryCodes.map((code) => (
                  <option key={code.code} value={code.code}>
                    {code.code} ({code.country})
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Mobile number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  handlePhoneChange(e); // Call handlePhoneChange on input change
                }}
                className="border-none bg-transparent outline-none flex-grow"
              />
            </div>

            {error && <p className="text-red-500 text-sm mb-3">{error}</p>}
            <div className="flex justify-start mt-5">
              <Button type="primary" htmlType="submit"  className="bg-colorPrimaryBlue text-white px-6 py-2 rounded-lg mb-6">Send OTP</Button>
            </div>
            <Link to="/login" className="mt-5 mb-10">Already a member?<span className="text-colorPrimaryBlue underline">Login</span></Link>
          </Form>
        </div>
      </div>
    </div>
{/* 
<div>
<div>
<div className="modal-overlay" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 999 }}>
        
      <div className="modal-container border-8 border-t-colorPrimaryGold " style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "#fff", padding: "20px", borderRadius: "8px", maxWidth: "400px", width: "80%" }}>
          
        <span style={{ float: "right", cursor: "pointer" }} onClick={closeLoginModal}>X</span>
          <div className="flex justify-center">

          <h2 className="font-bold text-xl">Create your account</h2>

          </div>
          <p className='text-[#0007] text-sm text-center mt-2'>Hey there! You are one step closer from unlocking the best of learnings & edvents.</p>

          <form 
          // onSubmit={handleSubmit}
          >
            <input
              type="text"
              placeholder="Mobile number"
              className="border-b-colorThemeBlue px-3 py-2 mt-10 rounded-lg mb-3 border-2 w-full"
            />


           
            <div className="flex justify-start mt-5">
            <button type="submit" className="bg-colorPrimaryBlue text-white px-6 py-2 rounded-lg mb-6">Send OTP</button>
            </div>
            <Link to="/login" className="mt-5 mb-10">Already a member?<span className="text-colorPrimaryBlue underline">Login</span></Link>
          </form>
        </div>
      </div>
</div>
</div> */}
</>
  );
}

export default CreateOtp;
