import React from 'react'
import img1 from "../../src/components/img/forget.png"
import CreateOtp from '../components/CreateOtp'
import GetOtp from '../components/GetOtp'
import { Link } from 'react-router-dom'

function RegisterSec() {
  return (
    <>
    <div className='mt-40 '></div>
    <div className='container mx-auto  '>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mb-10'>
        <div><img src={img1} alt='passowrd'/></div>
        <div>
            <div className='w-full lg:w-3/4 bg-white border-8 border-t-colorPrimaryBlue   shadow-lg shadow-#000 px-10 py-5'>
                <h1 className='font-bold text-center text-lg'>Few more details and then you are good to go.</h1>
                <form 
                // onSubmit={handleSubmit}
                >
                <div className='mt-5'>
                <label className='mt-10'>Full name</label>
                <input
                  type="text"
                  placeholder="Full name"
                //   value={username}
                //   onChange={handleUsernameChange}
                  className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"
                />
                </div>
                <div className='mt-5'>
                <label >Are you Parent or Student?</label>
                <input
                  type="password"
                  placeholder="select one"
                //   value={password}
                //   onChange={handlePasswordChange}
                  className="border-b-colorThemeBlue px-3 py-2 mt-2 rounded-lg mb-3 border-2 w-full"

                />
                </div>
                <div className="flex justify-start text-center mt-5">
                <Link  type="submit"  className="w-full bg-colorThemeBlue text-white px-6 py-2 rounded-lg">Proceed</Link>
                </div>
                </form>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default RegisterSec
