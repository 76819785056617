import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const data = [
  {
    key: 1,
    title: "Pitch Perfect: Crafting the Ultimate Pitch Deck",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider1.png",
    date: "29",
    month: "October",
  },
  {
    key: 2,
    title: "Web Designing, Digital Marketing, & Android Devel...",
    desc: "Aasha Pallav | In-person",
    image: "../../../images/slider2.png",
    date: "29",
    month: "October",
  },
  {
    key: 3,
    title: "Chai & Why?",
    desc: "Prithvi Chauhan | Recorded",
    image: "../../../images/slider3.png",
    date: "29",
    month: "October",
  },
  {
    key: 4,
    title: "Wealth Creation Webinar",
    desc: "Dr. Aniruddh Sahai | Live",
    image: "../../../images/slider4.png",
    date: "29",
    month: "October",
  },
  {
    key: 5,
    title: "Title 5 will be here",
    desc: "Lorem ipsum Dolor | Lorem ipsum",
    image: "../../../images/slider1.png",
    date: "29",
    month: "October",
  },
];
console.log(data);
const UpcomingEvents = () => {
  return (
    <>
      <section className=" bg-fixed pb-5 ">
        <div className="container mx-auto block ">
          <a href='/cinema_23'>
          <div className="mt-1 ">
            <Swiper
              slidesPerView={4}
              spaceBetween={40}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1, // 1 slide for screens smaller than 320px
                },
                768: {
                  slidesPerView: 2, // 2 slides for screens larger than 768px
                },
                1024: {
                  slidesPerView: 4, // 4 slides for screens larger than 1024px
                },
              }}
            >
              {data.map((item) => (
                <SwiperSlide className=" relative overflow-hidden">
                  <img src={item.image} className="mb-3 w-full object-contain" alt="" />
                  <div className="px-2">
                    <div className="text-blue-500 font-semibold">
                      {item.title}
                    </div>
                    <div className="text-sm">{item.desc}</div>
                    <div className="bg-white rounded-full shadow-sm absolute -top-3 -right-2  text-center p-3 ">
                      <div className="font-bold text-2xl text-gray-700 leading-5 pt-2">
                        {item.date}
                      </div>
                      <div className="text-xs">{item.month}</div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default UpcomingEvents;
