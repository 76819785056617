import axios from "axios";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";

const Brands = () => {
    const [brand, setBrand] = useState([])
    const imageurl = process.env.REACT_APP_API + "/pages/";

    const handleImageUrl = (imagePath) => {
        return `${imageurl}${imagePath}`;
    };

    const handleBrand = () => {
    
        axios({
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          url: `${process.env.REACT_APP_API}/pages/active_brand_list`,
        })
        .then(response => {
          const responseData = response?.data;
          setBrand(responseData.Success);
        })
        .catch(err => {
          console.log(err?.response?.data?.Error);
        });
      };

      useEffect(() => {
        handleBrand();
      }, []);

    return (
        <>
            <section className=" bg-fixed pb-5 ">
                <div className=" mx-auto block ">
                    <div className="border border-solid border-gray-200 rounded-3xl px-2 md:px-10 p-4">
                        <div className="text-xl md:text-3xl mb-10 mt-2 text-black font-semibold  text-center">
                            Brands we collaborate with
                        </div>
                        <div className="mt-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 border border-solid border-gray-200 shadow-lg rounded-3xl ">
                            {brand.map((item) => (
                                <div className="flex justify-center items-center">
                                    <img
                                       src={handleImageUrl(item.Logo)}
                                        className="mx-auto object-contain p-5"
                                        alt={item.Name}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Brands;
