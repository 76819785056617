import React from "react";

const Congratulations = () => {
  return (
    <div className="container pb-10">
      <div className="h-[60vh] w-screen flex items-center justify-center">
        <div className="text-center">
          <div className="font-bold text-3xl">
            Thank you for your registration!
          </div>
          <div className="my-5">
            We have shared your event ticket over email. See you on the other
            side!
          </div>

          <button className="text-base cursor-pointer w-fit  py-4 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white ">
            Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
