import {
  Breadcrumb,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tabs,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

const onChange = (key) => {
  console.log(key);
};


const SurveyForm = () => {
  const [form] = Form.useForm();
  const [userData, setUserData] = useState({});
  const [mobileVerificationRequired, setMobileVerificationRequired] = useState(false);
  const [emailVerificationRequired, setEmailVerificationRequired] = useState(false);
  const [currentStep, setCurrentStep] = useState("1");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [userInput, setUserInput] = useState({});
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    handlesurveyForm();
  }, []);

  useEffect(() => {
    if (currentStep === "3") {
      if (mobileVerificationRequired && emailVerificationRequired) {
        form.setFieldsValue({
          "Contact Number": mobileNumber,
          "Email": emailId
        });
      }
    }
  }, [currentStep, mobileNumber, emailId, mobileVerificationRequired, emailVerificationRequired, form]);

  const handleNext = () => {
    setCurrentStep("4");
  };

  const handlesurveyForm = () => {
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_SUR + "survey",
    })
      .then(async function (response) {
        const res = await response.data;
        if (res?.Success) {
          setUserData(res.Success);
          setMobileVerificationRequired(res.Success.Mobile_Verification);
          setEmailVerificationRequired(res.Success.Email_Verification);
          setMobileNumber(res.Success.User_Details.find(detail => detail.Parameter === "Contact Number")?.Value || "");
          setEmailId(res.Success.User_Details.find(detail => detail.Parameter === "Email")?.Value || "");

          // Set the initial step based on verification requirements
          let initialStep = "3"; // Default to User Details
          if (res.Success.Mobile_Verification) {
            initialStep = "1";
          } else if (res.Success.Email_Verification) {
            initialStep = "2";
          }
          setCurrentStep(initialStep);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data?.Error) {
          message.error(err.response.data.Error);
        }
      });
  };

  const handleInputChange = (parameter, value) => {
    setUserInput((prevInput) => ({
      ...prevInput,
      [parameter]: value,
    }));
  };

  const renderUserDetailsFields = () => {
    if (!userData.User_Details) return null;

    return userData.User_Details.map((detail) => (
      <Form.Item
        key={detail.Parameter}
        name={detail.Parameter}
        label={detail.Parameter}
        rules={[
          {
            required: detail.Required,
            message: `Please input your ${detail.Parameter}!`,
          },
          ...(detail.Parameter === "Contact Number"
            ? [
                {
                  pattern: /^[0-9]*$/,
                  message: 'Please enter a valid mobile number!',
                },
              ]
            : []),
          ...(detail.Parameter === "Email"
            ? [
                {
                  type: 'email',
                  message: 'Please enter a valid email!',
                },
                {
                  validator: (_, value) => {
                    if (!value || value.endsWith('@gmail.com')) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Email must end with @gmail.com!'));
                  },
                },
              ]
            : []),
        ]}
      >
        <Input
          placeholder={detail.Placeholder}
          onChange={(e) => handleInputChange(detail.Parameter, e.target.value)}
          disabled={
            (detail.Parameter === "Contact Number" && mobileVerificationRequired) ||
            (detail.Parameter === "Email" && emailVerificationRequired)
          }
        />
      </Form.Item>
    ));
  };

  const renderCareerDetails = () => {
    if (currentStep !== "4") return null;

    return (
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
      >
        {userData.Questions.map((question, index) => (
          <Form.Item
            key={index}
            name={question.Question}
            label={question.Question}
            rules={[
              {
                required: true,
                message: `Please select ${question.Question}`,
              },
            ]}
          >
            {question.DataType === "Dropdown" ? (
              <Select placeholder={question.Placeholder}>
                {question.Options.map((option, index) => (
                  <Select.Option key={index} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input placeholder={question.Placeholder} />
            )}
          </Form.Item>
        ))}
        <Form.Item>
          <Space>
            <Button
              className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
              onClick={() => setCurrentStep("3")}
            >
              Back
            </Button>
            <Button
              className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const handleMobileVerificationSubmit = (values) => {
    setMobileNumber(values["Contact Number"]);
    setCurrentStep("3");
  };

  const handleEmailVerificationSubmit = (values) => {
    setEmailId(values["Email"]);
    setCurrentStep("3");
  };

  const submitForm = (values) => {
    const userDetails = userData.User_Details.reduce((details, detail) => {
      if (detail.Parameter === "Contact Number") {
        details[detail.Parameter] = mobileNumber || userInput[detail.Parameter];
      } else if (detail.Parameter === "Email") {
        details[detail.Parameter] = emailId || userInput[detail.Parameter];
      } else {
        details[detail.Parameter] = userInput[detail.Parameter];
      }
      return details;
    }, {});

    const survey = userData.Questions.reduce((questions, question) => {
      questions[question.Question] = values[question.Question];
      return questions;
    }, {});

    const postData = {
      formid: userData.ID,
      user_details: userDetails,
      survey: survey,
    };
    axios
      .post(process.env.REACT_APP_SUR + "survey_form", postData)
      .then((response) => {
        const res = response.data;
        if (res?.Success) {
          message.success(res.Success);
        }
      })
      .catch((error) => {
        if (error.response?.data?.Error) {
          message.error(error.response.data.Error);
        }
      });
  };

  const items = [
    ...(mobileVerificationRequired
      ? [
          {
            key: "1",
            label: "Mobile Verification",
            children: (
              <div>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleMobileVerificationSubmit}
                  autoComplete="off"
                >
                  <Form.Item
                    name="Contact Number"
                    label="Enter Mobile Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your mobile number!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: 'Please enter a valid mobile number!',
                      },
                    ]}
                  >
                    <InputNumber type="number" placeholder="Enter Mobile Number" className="w-[250px]" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ),
          },
        ]
      : []),
    ...(emailVerificationRequired
      ? [
          {
            key: "2",
            label: "Email Verification",
            children: (
              <div>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleEmailVerificationSubmit}
                  autoComplete="off"
                >
                  <Form.Item
                    name="Email"
                    label="Enter Email ID"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                      {
                        type: 'email',
                        message: 'Please enter a valid email!',
                      },
                      {
                        validator: (_, value) => {
                          if (!value || value.endsWith('@gmail.com')) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Email must end with @gmail.com!'));
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email ID" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            ),
          },
        ]
      : []),
    {
      key: "3",
      label: "User Details",
      children: (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          // onFinish={submitForm}
          // onFinishFailed={onFinishFailed}
        >
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1">
          {renderUserDetailsFields()}
          </div>
          <Form.Item>
            <Button
              className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
              onClick={() => setCurrentStep("1")}
            >
              Back
            </Button>
            <Button
              className="text-base cursor-pointer w-fit py-1 px-10 bg-colorThemeBlue hover:bg-colorThemeBlue/70 rounded-md text-white"
              type="primary"
              // htmlType="submit"
              onClick={handleNext}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: "4",
      label: "Career Details",
      children: renderCareerDetails(),
    },
  ];


  return (
    <div className="container mx-auto pb-10">
      <div className="mb-5 pt-5 px-10">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "Homepage",
            },
            {
              title: "Survey Form",
            },
          ]}
        />
      </div>
      <div className="flex px-1 md:px-10">
        <div className="w-full md:w-8/12 p-4 border-r border border-l-0 border-t-0 border-b-0">
          <div className="my-2 text-4xl">Survey Form</div>
          <Tabs
            activeKey={currentStep}
            items={items}
            onChange={onChange}
          />
        </div>
        <div className="md:w-4/12 md:block hidden">
          <div className="h-full flex items-center justify-center">
            <div className="mt-10">
              <img
                src="../../../images/survey.png"
                className="object-contain mx-auto p-4"
                alt="Survey"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyForm;
